import { boolean } from "yup";

export interface IDividendActor {
  id: string;
  name: string;
  comment: string;
  created_at: Date;
}
export interface IDividendDataType {
  key: string;
  id: number;
  status: string;
  security: string;
  noUnits: number;
  frequency: string;
  amount: number;
  dividendPerUnit?: number;
  amount_per_share?: number;
  noSmartfolios: number;
  noInvestments: number;
  record_date: string;
  results: Array<Record<string, unknown>>;
  file_id?: number;
  comment: IDividendCommentInfo;
  maker: IDividendActor;
  checker?: IDividendActor;
  ticker: { id: number; symbol: string; isin?: number };
  isRebalanced: boolean;
}
export interface IDividendCommentInfo {
  comment: string;
  name: string;
  user?: string;
  date: string;
  createdAt?: string;
}

export type TDividendDataIndex = keyof IDividendDataType;

export type TDividedColumns = (
  getColumnSearchProps: (dataIndex: TDividendDataIndex) => ColumnType<IDividendDataType>
) => any;

export interface IDividendViewOrConfirm {
  data: IDividendDataType;
}
export interface IDividendModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: IDividendModalState) => void;
  data?: IDividendDataType;
  modalType?: EDividendModalType;
  attachmentUploadNeeded?: boolean;
  form?: any;
}
export interface IDividendPreviewProps {
  newDividend: boolean;
  status?: string;
  confirmationNeeded?: boolean;
  setStep?: (step: string) => void;
  setIsModalOpen?: (state: IDividendModalState) => void;
  setConfirmationStep?: (step: "confirm" | "confirmed") => void;
  data?: IDividendDataType;
  formValuesToDisplay?: any;
  form?: any;
  handleCancel: () => void;
}

export interface IDividendModalState {
  type: EDividendModalType;
  value: boolean;
}

export enum EDividendStatus {
  UNDISTRIBUTED = "UNDISTRIBUTED",
  FULLY_DISTRIBUTED = "FULLY-DISTRIBUTED",
  CANCELED = "CANCELED"
}

export enum EDividendSteps {
  FILL = "FILL",
  CONFIRM = "CONFIRM",
  UPLOAD_ATTACHMENT = "UPLOAD_ATTACHEMENT"
}

export enum EDividendFrequencies {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI-ANNUAL",
  ANNUAL = "ANNUAL",
  OTHER = "OTHERS"
}

export enum EDividendModalType {
  UPLOAD_DIVIDEND_ATTACHMENT,
  CONFIRM_DIVIDEND,
  CREATE_DIVIDEND,
  PREVIEW_DIVIDEND,
  UNDEFINED
}
