import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const strategySettingServiceApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getStrategies: builder.query<any, any>({
      query: ({pageNumber, pageSize}) => ({
        url: urlPrepare(`/strategies?page_number=${pageNumber}&page_size=${pageSize}`),
        headers: {
          "x-fields":
            "_id,smartfolio_name,asset_class,market,portal_name,name,description,solution_body,basket_body,notional,creation_date,frequency",
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyNav: builder.query<any, any>({
      query: ({id}) =>
        urlPrepare(`/strategies/${id}/nav`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyPerformance: builder.query<any, any>({
      query: ({id}) =>
        urlPrepare(`/strategies/${id}/performance`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyGroups: builder.query<any, void>({
      query: () => urlPrepare("/pam/strategy-group/"),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyGroupsById: builder.query<any, string>({
      query: (strategyGroupsId) => urlPrepare(`/pam/strategy-group/${strategyGroupsId}`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyGroupsByManagerId: builder.query<any, string>({
      query: (strategyManagerId) =>
        urlPrepare(`/pam/strategy-group-by-manager/?managerId=${strategyManagerId}`),
      providesTags: ["StrategieSettingService"]
    }),
    getStrategyById: builder.query<any, string>({
      query: (strategie_id) =>
        urlPrepare(`/strategies/${strategie_id}`),
      providesTags: ["StrategieSettingService"]
    }),
    newStrategySetting: builder.mutation<any, any>({
      query: (newStrategy) => ({
        url: urlPrepare(`/strategies`),
        method: "POST",
        body: newStrategy
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    editStrategy: builder.mutation<any, any>({
      query: ({ editedStrategie, id }) => ({
        url: urlPrepare(`/strategies/${id}`),
        method: "PUT",
        body: editedStrategie
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    deleteStrategy: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(`/strategies/${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    deleteMultipleStrategies: builder.mutation<any, any>({
      query: (items) => ({
        url: urlPrepare(`/settings/strategies?items=${items}`),
        method: "DELETE"
      }),
      invalidatesTags: ["StrategieSettingService"]
    }),
    getAllFrequencies: builder.query<any, any>({
      query: () => ({
        url: urlPrepare(`/settings/parameters/allfrequencies`)
      })
    })
  })
});

export const {
  useGetStrategiesQuery,
  useGetStrategyNavQuery,
  useGetStrategyPerformanceQuery,
  useGetStrategyGroupsQuery,
  useGetStrategyGroupsByIdQuery,
  useGetStrategyGroupsByManagerIdQuery,
  useGetStrategyByIdQuery,
  useNewStrategySettingMutation,
  useEditStrategyMutation,
  useDeleteStrategyMutation,
  useDeleteMultipleStrategiesMutation,
  useGetAllFrequenciesQuery
} = strategySettingServiceApi;
