import { TYPES } from "./actions";

let initState = {
  news: [],
  error: {},
  isLoading: false
};

export function newsASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_NEWS_ARTICLES_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.GET_NEWS_ARTICLES: {
      return {
        ...state,
        news: action.payload,
        isLoading: false
      };
    }
    case TYPES.GET_NEWS_ARTICLES_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    }
    default:
      return state;
  }
}
