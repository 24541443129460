import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const TYPES = {
  GET_ALL_SOLUTIONS: "GET_ALL_SOLUTIONS",
  GET_ALL_SOLUTIONS_ERROR: "GET_ALL_SOLUTIONS_ERROR",
  GET_NEWS_SOLUTIONS_IN_PROGRESS: "GET_NEWS_SOLUTIONS_IN_PROGRESS",

  GET_SOLUTION_BY_ID: "GET_SOLUTION_BY_ID",
  GET_SOLUTION_BY_ID_ERROR: "GET_SOLUTION_BY_ID_ERROR",
  GET_SOLUTION_BY_ID_IS_LOADING: "GET_SOLUTION_BY_ID_IS_LOADING",

  ADD_NEW_SOLUTION: "ADD_NEW_SOLUTION",
  ADD_NEW_SOLUTION_IN_PROGRESS: "ADD_NEW_SOLUTION_IN_PROGRESS",
  ADD_NEW_SOLUTION_ERROR: "ADD_NEW_SOLUTION_ERROR",

  EDIT_SOLUTION: "EDIT_SOLUTION",
  EDIT_SOLUTION_IN_PROGRESS: "EDIT_SOLUTION_IN_PROGRESS",
  EDIT_SOLUTION_ERROR: "EDIT_SOLUTION_ERROR",

  DELETE_SOLUTION: "DELETE_SOLUTION",
  DELETE_SOLUTION_ERROR: "DELETE_SOLUTION_ERROR",

  REMOVE_SOLUTIONS_FROM_LIST: "REMOVE_SOLUTIONS_FROM_LIST"
};

export function getSolutions() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_NEWS_SOLUTIONS_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_GET_SOLUTIONS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_SOLUTIONS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_SOLUTIONS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getSolutionById(solution_id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_SOLUTION_BY_ID_IS_LOADING });
    axiosInstance
      .get(process.env.REACT_APP_GET_SOLUTION_BY_ID + { solution_id }.solution_id)
      .then((res) => {
        dispatch({
          type: TYPES.GET_SOLUTION_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_SOLUTION_BY_ID_ERROR,
          payload: err.message
        });
      });
  };
}

export function newSolution(newSolution) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_NEW_SOLUTION_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_ADD_NEW_SOLUTION, newSolution)
      .then((response) => {
        dispatch({
          type: TYPES.ADD_NEW_SOLUTION,
          payload: response
        });
        Swal.fire({
          icon: "success",
          title: "Solution created successfully",
          confirmButtonText: "Done",
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/solution");
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_NEW_SOLUTION_ERROR,
          payload: err.response
        });
        toast.error("Faild To Add Solution");
        if (err.response) {
          throw err.response;
        }
      });
  };
}

/*
 ** Callback (error))
 */
export function editSolution(editedSolution, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.EDIT_SOLUTION_IN_PROGRESS });
    axiosInstance
      .put(process.env.REACT_APP_ADD_NEW_SOLUTION + id, editedSolution)
      .then((res) => {
        dispatch({
          type: TYPES.EDIT_SOLUTION,
          payload: res
        });
        Swal.fire({
          icon: "success",
          title: "Solution edited successfully",
          confirmButtonText: "Done",
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/solution");
          }
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.EDIT_SOLUTION_ERROR,
          payload: err.message
        });
        Swal.fire({
          title: "Solution can't be edited!",
          text: typeof message === "string" ? message : "All solution fields are required!",
          icon: "error",
          type: "error"
        });
        if (err.response) {
          throw err.response;
        }
      });
  };
}

export function deleteSolution(id) {
  return function (dispatch) {
    axiosInstance
      .delete(process.env.REACT_APP_DELETE_SOLUTION_BY_ID + id)
      .then(() => {
        Swal.fire({
          title: "Deleted!",
          text: "Solution deleted!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        }).then(() => {
          dispatch({
            type: TYPES.REMOVE_SOLUTIONS_FROM_LIST,
            payload: [id]
          });
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.DELETE_SOLUTION_ERROR,
          payload: message || "Solution is already used in one or many strategies"
        });
        Swal.fire({
          title: "Delete failed",
          text:
            typeof message === "string"
              ? message
              : "Solution is already used in one or many strategies",
          icon: "error",
          type: "error"
        });
      });
  };
}

export const deleteSolutions = async (items) => {
  try {
    const response = await axiosInstance.delete(`/settings/solutions?items=${items}`);
    return response.data;
  } catch (error) {
    return error?.response.data || { error: "Failed to delete solutions" };
  }
};
