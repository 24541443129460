import { TYPES } from "./actions";
const initState = {
  fees: [],
  feesIsLoading: false,
  clientFees: [],
  errors: undefined,
};

export function feesReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_FEES: {
      return {
        ...state,
        fees: action.payload,
        feesIsLoading: false
      };
    }

    case TYPES.FEES_IS_LOADING: {
      return {
        ...state,
        feesIsLoading: true
      };
    }

    case TYPES.GET_CLIENT_FEES: {
      return {
        ...state,
        clientFees: action.payload,
      };
    }

    case TYPES.GET_ORDER: {
      return {
        ...state,
        order: action.payload,
      };
    }
    case TYPES.FEES_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default:
      return state;
  }
}
