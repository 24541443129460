import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const dividendApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getDividend: builder.query<any, { page_number: number }>({
      query: (params) =>
        urlPrepare(`${process.env.REACT_APP_DIVIDENDS_MAIN_PATH}/`, null, {
          ...params,
          page_size: 10,
          sort_order: "desc"
        }),
      providesTags: ["Dividends"]
    }),
    getDividendUnits: builder.query<any, string | null>({
      query: (date) => {
        const query = date ? `?positions_at=${date}` : "";

        return urlPrepare(`${process.env.REACT_APP_GET_DIVIDEND_UNITS}/${query}`);
      },
      providesTags: ["Dividends"]
    }),
    createDividend: builder.mutation<any, any>({
      query: (newDividend) => ({
        url: urlPrepare(process.env.REACT_APP_DIVIDENDS_MAIN_PATH),
        method: "POST",
        body: newDividend
      }),
      invalidatesTags: ["Dividends"]
    }),
    confirmDividend: builder.mutation<any, any>({
      query: ({ id, results, comment }) => ({
        url: urlPrepare(`${process.env.REACT_APP_DIVIDENDS_MAIN_PATH}/${id}/approve`),
        method: "POST",
        body: { results, comment }
      }),
      invalidatesTags: ["Dividends"]
    }),
    rejectDividend: builder.mutation<any, any>({
      query: ({ id, comment }) => ({
        url: urlPrepare(`${process.env.REACT_APP_DIVIDENDS_MAIN_PATH}/${id}/cancel`),
        method: "POST",
        body: { comment }
      }),
      invalidatesTags: ["Dividends"]
    }),
    uploadDividendAttachement: builder.mutation<any, any>({
      query: ({ dividendId, formData }) => ({
        url: urlPrepare(`${process.env.REACT_APP_DIVIDENDS_MAIN_PATH}/${dividendId}/upload-file`),
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["Dividends"]
    }),
    getDividendAttachment: builder.query<any, any>({
      query: (fileId: number) => ({
        url: urlPrepare(process.env.REACT_APP_GET_DIVIDEND_ATTACHMENT, { id: fileId })
      }),
      providesTags: ["Dividends"]
    })
  })
});

export const {
  useGetDividendQuery,
  useGetDividendUnitsQuery,
  useGetDividendAttachmentQuery,
  useCreateDividendMutation,
  useConfirmDividendMutation,
  useRejectDividendMutation,
  useUploadDividendAttachementMutation
} = dividendApi;
