import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";

export const TYPES = {
  GET_STATEMENTS: "GET_STATEMENTS",
  STATEMENTS_LOADING: "STATEMENTS_LOADING",
  STATEMENT_FILE: "STATEMENT_FILE",
  STATEMENTS_ERRORS: "STATEMENTS_ERRORS",
};

export function getAllStatements(owner) {
  return async (dispatch) => {
    dispatch({
      type: TYPES.STATEMENTS_LOADING,
    });
    try {
      const route = owner
        ? urlPrepare(process.env.REACT_APP_CLIENT_STATEMENTS_ROUTE, { owner })
        : `${process.env.REACT_APP_STATEMENTS_ROUTE}`;
      const { data } = await axiosInstance.get(route);
      dispatch({
        type: TYPES.GET_STATEMENTS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.STATEMENTS_ERRORS,
        payload: error.message,
      });
      console.error(error);
    }
  };
}
