import { TYPES } from "./actions";

let initState = {
  allRiskProfiles: [],
  riskProfile: {},
  addNewRiskProfileRes: {},
  isLoadingGet: false,
  isLoadingAdd: false
};

export function riskProfileASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_ALL_RISK_PROFILE: {
      return {
        ...state,
        allRiskProfiles: action.payload,
        isLoadingGet: false
      };
    }
    case TYPES.GET_RISK_PROFILE_BY_ID: {
      return {
        ...state,
        riskProfile: action.payload,
        isLoadingGet: false
      };
    }
    case TYPES.ADD_RISK_PROFILE: {
      return {
        ...state,
        addNewRiskProfileRes: action.payload,
        isLoadingAdd: false
      };
    }
    case TYPES.GET_RISK_PROFILE_IN_PROGRESS: {
      return {
        ...state,
        isLoadingGet: true
      };
    }
    case TYPES.ADD_RISK_PROFILE_IN_PROGRESS: {
      return {
        ...state,
        isLoadingAdd: true
      };
    }
    default:
      return state;
  }
}
