import { TYPES } from "./actions";

let initState = {
  groups: [],
  users:[],
  group: {},
  groupManager: {},
  err: {},
};

export function groupASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_ALL_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case TYPES.GET_USERS_INFOS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case TYPES.GET_GROUP_BY_ID: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case TYPES.GET_GROUP_BY_MANAGER_ID: {
      return {
        ...state,
        groupManager: action.payload,
      };
    }
    case TYPES.ADD_NEW_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case TYPES.UPDATE_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case TYPES.DELETE_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }

    case TYPES.GET_ALL_GROUPS_ERROR: {
      return {
        ...state,
        err: action.payload,
      };
    }
    case TYPES.GET_GROUP_BY_ID_ERROR: {
      return {
        ...state,
        err: action.payload,
      };
    }
    case TYPES.ADD_NEW_GROUP_ERROR: {
      return {
        ...state,
        err: action.payload,
      };
    }
    case TYPES.UPDATE_GROUP_ERROR: {
      return {
        ...state,
        err: action.payload,
      };
    }
    case TYPES.DELETE_GROUP_ERROR: {
      return {
        ...state,
        err: action.payload,
      };
    }

    default:
      return state;
  }
}
