import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import Swal from "sweetalert2";

export const TYPES = {
  GET_INVESTMENTS: "GET_INVESTMENTS",
  GET_INVESTMENTS_SNAPSHOTS: "GET_INVESTMENTS_SNAPSHOTS",
  GET_INVESTMENTS_SNAPSHOTS_IN_PROGRESS: "GET_INVESTMENTS_SNAPSHOTS_IN_PROGRESS",
  GET_INVESTMENTS_SNAPSHOTS_ERROR: "GET_INVESTMENTS_SNAPSHOTS_ERROR",
  GET_WITHDRAWALS: "GET_WITHDRAWALS",
  GET_ORDERS_DEPOSITS: "GET_ORDERS_DEPOSTS",
  EXIT_FROM_INVESTMENT: "EXIT_FROM_INVESTMENT",
  EXIT_FROM_INVESTMENT_ERROR: "EXIT_FROM_INVESTMENT_ERROR",

  CANCEL_INVESTMENT: "CANCEL_INVESTMENT",
  CANCEL_INVESTMENT_ERROR: "CANCEL_INVESTMENT_ERROR",

  GET_DEPOSITS: "GET_DEPOSITS",
  GET_WITHDRAWS: "GET_WITHDRAWS",
  GET_CLIENT_INVESTMENTS: "GET_CLIENT_INVESTMENTS",
  GET_CLIENT_DEPOSITS: "GET_CLIENT_DEPOSITS",
  GET_CLIENT_WITHDRAWS: "GET_CLIENT_WITHDRAWS",
  GET_ORDER: "GET_ORDER",
  GET_ORDERS_ERROR: "GET_ORDERS_ERROR",
  GET_ORDER_ERROR: "GET_ORDER_ERROR",
  IS_LOADING: "IS_LOADING"
};

export function exitFromInvestment(investmentId) {
  return function (dispatch) {
    axiosInstance
      .put(`/order/investment/${investmentId}/exit`)
      .then((response) => {
        dispatch({
          type: TYPES.EXIT_FROM_INVESTMENT,
          payload: response
        });
        Swal.fire({
          icon: "success",
          title: "Exited from investment successfully",
          confirmButtonText: "Done"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/investments");
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.EXIT_FROM_INVESTMENT_ERROR,
          payload: err?.response?.data || {
            message: "Failed to exit from investment"
          }
        });
        Swal.fire({
          title: "Error",
          text: "Failed to exit from investment",
          type: "error"
        });
      });
  };
}

export function cancelInvestment(investmentId) {
  return function (dispatch) {
    axiosInstance
      .put(`/order/investment/${investmentId}/cancel`)
      .then((response) => {
        dispatch({
          type: TYPES.CANCEL_INVESTMENT,
          payload: response
        });
        Swal.fire({
          icon: "success",
          title: "Investment Canceled Successfully",
          confirmButtonText: "Done"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/investments");
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CANCEL_INVESTMENT_ERROR,
          payload: err?.response?.data || {
            message: "Failed To Cancel Investment"
          }
        });
        Swal.fire({
          title: "Error",
          text: "Failed To Cancel Investment",
          type: "error"
        });
      });
  };
}

const getActionType = (type, isSingleClientID) => {
  switch (type) {
    case "investments": {
      return isSingleClientID ? TYPES.GET_CLIENT_INVESTMENTS : TYPES.GET_INVESTMENTS;
    }

    case "deposits": {
      return isSingleClientID ? TYPES.GET_CLIENT_DEPOSITS : TYPES.GET_DEPOSITS;
    }

    case "withdraws": {
      return isSingleClientID ? TYPES.GET_CLIENT_WITHDRAWS : TYPES.GET_WITHDRAWS;
    }

    default:
      return null;
  }
};

export function getInvestmentsSnapshots(investmentId, inceptionDate) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_INVESTMENTS_SNAPSHOTS_IN_PROGRESS });
    axiosInstance
      .get(`/meta/investments/snapshots/${investmentId}/${inceptionDate}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_INVESTMENTS_SNAPSHOTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_INVESTMENTS_SNAPSHOTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getOrders(type, status) {
  return function (dispatch) {
    dispatch({
      type: TYPES.IS_LOADING,
      payload: true
    });
    dispatch({
      type: TYPES.GET_ORDERS_ERROR,
      payload: undefined
    });

    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_ORDERS, { type }, { status }))
      .then((res) => {
        const dispatchType = getActionType(type, false);
        dispatch({
          type: TYPES.IS_LOADING,
          payload: false
        });
        dispatch({
          type: dispatchType,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDERS_ERROR,
          payload: err.response.status
        });
        dispatch({
          type: TYPES.IS_LOADING,
          payload: false
        });
      });
  };
}

export function getOrdersByClientId(type, status, id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ORDERS_ERROR,
      payload: undefined
    });

    return axiosInstance
      .get(
        urlPrepare(
          process.env.REACT_APP_CLIENT_ORDERS,
          {
            type,
            id
          },
          { status }
        )
      )
      .then((res) => {
        const dispatchType = getActionType(type, true);

        dispatch({
          type: dispatchType,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDERS_ERROR,
          payload: err.response.status
        });
      });
  };
}

export function getOrderById(type, id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ORDER,
      payload: null
    });
    dispatch({
      type: TYPES.GET_ORDER_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_ORDER_BY_ID, { type, id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.message
        });
      });
  };
}

//
export function getLeanOrderById(type, id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ORDER,
      payload: null
    });
    return axiosInstance
      .get(urlPrepare(`/payment/transaction?provider=${type}&intentId=${id}`))
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.message
        });
      });
  };
}

export function getWithdrawals(type) {
  return function (dispatch) {
    dispatch({
      type: TYPES.IS_LOADING,
      payload: true
    });
    return axiosInstance
      .get(urlPrepare(`/withdrawals/orders?status=${type}`))
      .then((res) => {
        dispatch({
          type: TYPES.GET_WITHDRAWALS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.message
        });
      })
      .finally(() => {
        dispatch({
          type: TYPES.IS_LOADING,
          payload: false
        });
      });
  };
}
export function getAllOrdersDeposits(type) {
  ///orders/deposits/all
  return function (dispatch) {
    dispatch({
      type: TYPES.IS_LOADING,
      payload: true
    });
    return axiosInstance
      .get(urlPrepare(type ? `/orders/deposits/all?status=${type}` : `/orders/deposits/all`))
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDERS_DEPOSITS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.message
        });
      })
      .finally(() => {
        dispatch({
          type: TYPES.IS_LOADING,
          payload: false
        });
      });
  };
}

export function setOrder(order) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ORDER,
      payload: order
    });
  };
}

export function approveOrder(id, approvalData, type) {
  return function (dispatch) {
    const formData = new FormData();
    Object.keys(approvalData).forEach((elem) => {
      formData.append(elem, approvalData[elem]);
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_EXECUTE_ORDER, { type, id }), formData)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function approveLeanOrder(id, approvalData, intentId) {
  return function (dispatch) {
    const formData = new FormData();
    Object.keys(approvalData).forEach((elem) => {
      formData.append(`${elem}`, approvalData[elem]);
    });
    formData.append("intentId", intentId);
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_EXECUTE_LEANORDER, { id }), formData)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function createDeposit(id, formData) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ORDER_ERROR,
      payload: ""
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CREATE_DEPOSIT, { id }), formData)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
        return res.data;
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.response.data.message
        });
        throw err;
      });
  };
}

export function cancelDeposit(id, provider = "manual") {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CANCEL_DEPOSIT, { id }), {
        provider
      })
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
        return res.data;
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.response
        });
      });
  };
}

export function createWithdraw(id, formData) {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ORDER_ERROR,
      payload: ""
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CREATE_WITHDRAW, { id }), formData)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
        return res.data;
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.response.data.message
        });
        throw err;
      });
  };
}

export function cancelWithdraw(id) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CANCEL_WITHDRAW, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_ORDER,
          payload: res.data
        });
        return res.data;
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDER_ERROR,
          payload: err.response
        });
      });
  };
}
