import { message } from "antd";

import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import { toast } from "react-toastify";

export const TYPES = {
  GET_REBALANCING_REQUQESTS: "GET_REBALANCING_REQUQESTS",
  GET_REBALANCING_REQUQEST: "GET_REBALANCING_REQUQEST",
  CREATE_REBALANCING_REQUQEST: "CREATE_REBALANCING_REQUQEST",
  REBALANCING_LOADING: "REBALANCING_LOADING",
  REBALANCINGS_LOADING: "REBALANCINGS_LOADING"
};

export function getRebalancingRequests(queryParams) {
  return function (dispatch) {
    const queryString = new URLSearchParams(queryParams).toString();
    dispatch({
      type: TYPES.REBALANCINGS_LOADING,
      payload: true
    });
    return axiosInstance
      .get(process.env.REACT_APP_REBALANCING + "?" + queryString)
      .then((res) => {
        dispatch({
          type: TYPES.GET_REBALANCING_REQUQESTS,
          payload: res.data
        });
      })
      .finally(() => {
        dispatch({
          type: TYPES.REBALANCINGS_LOADING,
          payload: false
        });
      });
  };
}

export const getRebalancingRequestById = (id) => async (dispatch) => {
  const response = await axiosInstance.get(
    urlPrepare(process.env.REACT_APP_GET_REBALANCING_REQUEST, { id })
  );
  dispatch({
    type: TYPES.GET_REBALANCING_REQUQEST,
    payload: response.data
  });
};

export function createRebalancingRequest(data) {
  return async function (dispatch) {
    try {
      dispatch({
        type: TYPES.REBALANCING_LOADING,
        payload: true
      });
      let { data: reb } = await axiosInstance.post(
        process.env.REACT_APP_REBALANCING,
        data
      );
      message.success("rebalancing request created successfully");
      dispatch({
        type: TYPES.REBALANCING_LOADING,
        payload: false
      });
      dispatch({
        type: TYPES.CREATE_REBALANCING_REQUQEST,
        payload: reb
      });
    } catch (err) {
      dispatch({
        type: TYPES.REBALANCING_LOADING,
        payload: false
      });
      message.error("rebalancing request creation failed");
      return err;
    }
  };
}

export function simulateRebalancing(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.REBALANCING_LOADING,
      payload: true
    });

    return axiosInstance
      .post(
        urlPrepare(process.env.REACT_APP_SIMULATE_REBALANCING, {
          id
        })
      )
      .then((res) => {
        dispatch(getRebalancingRequestById(id));

        dispatch({
          type: TYPES.REBALANCING_LOADING,
          payload: false
        });
        toast.success("Tested Successfully");
        return res.data;
      })
      .catch((err) => {
        toast.error(err.message || "Test Failed !");
        dispatch({
          type: TYPES.REBALANCING_LOADING,
          payload: false
        });
        return err;
      });
  };
}

export function executeRebalancingRequest(id, investmentsIds) {
  return function (dispatch) {
    dispatch({
      type: TYPES.REBALANCING_LOADING,
      payload: true
    });

    return axiosInstance
      .post(
        urlPrepare(
          process.env.REACT_APP_EXECUTE_REBALANCING_REQUEST,
          {
            id
          },
          { investmentsIds }
        )
      )
      .then((res) => {
        dispatch(getRebalancingRequestById(id));

        dispatch({
          type: TYPES.REBALANCING_LOADING,
          payload: false
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.REBALANCING_LOADING,
          payload: false
        });
        return err;
      });
  };
}

export function cancelRebalancingRequest(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.REBALANCING_LOADING,
      payload: true
    });
    return axiosInstance
      .post(
        urlPrepare(process.env.REACT_APP_CANCEL_REBALANCING_REQUEST, {
          id
        })
      )
      .then((res) => {
        dispatch(getRebalancingRequestById(id));

        dispatch({
          type: TYPES.REBALANCING_LOADING,
          payload: false
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.REBALANCING_LOADING,
          payload: false
        });
        return err;
      });
  };
}
