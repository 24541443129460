import { TYPES } from "./actions";

let initState = {
  users: [],
  user: {},
  crm: {},
  usersByrole: [],
  assignedClients: "",
  investment: {},
  investmentError: {},
  isLoading: false,
  assigningClientsIsLoading: false,
  newPamInvestmentInProgress: false,
  getAllUsersIsLoading: false
};

export function userReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_USER: {
      return {
        ...state,
        user: action.payload,
        id: action.payload._id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        title: action.payload.title,
        redirect: "DASHBOARD"
      };
    }
    case TYPES.GET_USERS_BY_ROLE_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.PAM_NEW_INVESTMENT_IN_PROGRESS: {
      return {
        ...state,
        newPamInvestmentInProgress: true
      };
    }

    case TYPES.ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS: {
      return {
        ...state,
        assigningClientsIsLoading: true
      };
    }

    case TYPES.GET_USERS: {
      return {
        ...state,
        users: action.payload,
        getAllUsersIsLoading: false
      };
    }
    
    case TYPES.GET_USERS_IN_PROGRESS: {
      return {
        ...state,
        getAllUsersIsLoading: true,
      }
    }
    case TYPES.GET_USERS_ROLE: {
      return {
        ...state,
        usersByrole: action.payload,
        isLoading: false
      };
    }

    case TYPES.GET_USERBYID: {
      return {
        ...state,
        user: action.payload
      };
    }
    case TYPES.GET_CRMBYCLIENTID: {
      return {
        ...state,
        crm: action.payload
      };
    }
    case TYPES.ASSIGN_CLIENTS_TO_MANAGER: {
      return {
        ...state,
        assignedClients: action.payload,
        assigningClientsIsLoading: false
      };
    }
    case TYPES.PAM_NEW_INVESTMENT: {
      return {
        ...state,
        investment: action.payload,
        newPamInvestmentInProgress: false
      };
    }
    case TYPES.PAM_NEW_INVESTMENT_ERROR: {
      return {
        ...state,
        investmentError: action.payload,
        newPamInvestmentInProgress: false
      };
    }
    default:
      return state;
  }
}
