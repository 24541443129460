import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const BasketApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getBaskets: builder.query<any, any>({
      query: ({ pageNumber, pageSize }) =>
        urlPrepare(`/baskets/?page_number=${pageNumber}&page_size=${pageSize}`),
      providesTags: ["Baskets"]
    }),
    createBasket: builder.mutation<any, any>({
      query: (body) => ({
        url: urlPrepare(`/baskets`),
        method: "POST",
        body
      }),
      invalidatesTags: ["Baskets"]
    }),
    getBasketById: builder.query<any, any>({
      query: (id) => urlPrepare(`/baskets/${id}`),
      providesTags: ["Baskets"]
    }),
    updateBasket: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/baskets/${id}`),
        method: "PUT",
        body
      }),
      invalidatesTags: ["Baskets"]
    }),
    deleteBasket: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(`/baskets/${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["Baskets"]
    })
  })
});

export const {
  useGetBasketByIdQuery,
  useGetBasketsQuery,
  useCreateBasketMutation,
  useDeleteBasketMutation,
  useUpdateBasketMutation
} = BasketApi;
