import Swal from "sweetalert2";

import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
  GET_ALL_BASKETS: "GET_ALL_BASKETS",
  GET_ALL_BASKETS_ERROR: "GET_ALL_BASKETS_ERROR",
  ADD_NEW_BASKET_IN_PROGRESS: "ADD_NEW_BASKET_IN_PROGRESS",
  BASKET_LOADING: "BASKET_LOADING",

  ADD_ALL_BASKETS_WITH_SENTIMENTS: "ADD_ALL_BASKETS_WITH_SENTIMENTS",

  GET_BASKET_BY_ID: "GET_BASKET_BY_ID",
  GET_BASKET_BY_ID_IN_PROGRESS: "GET_BASKET_BY_ID_IN_PROGRESS",
  GET_BASKET_BY_ID_ERROR: "GET_BASKET_BY_ID_ERROR",

  ADD_NEW_BASKET: "ADD_NEW_BASKET",
  ADD_NEW_BASKET_ERROR: "ADD_NEW_BASKET_ERROR",

  EDIT_BASKET: "EDIT_BASKET",
  EDIT_BASKET_IN_PROGRESS: "EDIT_BASKET_IN_PROGRESS",
  EDIT_BASKET_ERROR: "EDIT_BASKET_ERROR",

  DELETE_BASKET: "DELETE_BASKET",
  DELETE_BASKET_ERROR: "DELETE_BASKET_ERROR",

  REMOVE_BASKETS_FROM_LIST: "REMOVE_BASKETS_FROM_LIST"
};

export function getBaskets() {
  return function (dispatch) {
    dispatch({ type: TYPES.BASKET_LOADING, payload: true });
    axiosInstance
      .get(process.env.REACT_APP_GET_BASKETS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_BASKETS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_BASKETS_ERROR,
          payload: err.response.data || { message: "Failed to fetch baskets" }
        });
      });
  };
}

export function getBasketById(basket_id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_BASKET_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_GET_BASKET_BY_ID + { basket_id }.basket_id)
      .then((res) => {
        dispatch({
          type: TYPES.GET_BASKET_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_BASKET_BY_ID_ERROR,
          payload: err?.response.data || { message: "Failed to fetch basket" }
        });
      });
  };
}

export function newBasket(newBasket) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_NEW_BASKET_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_ADD_NEW_BASKET, newBasket)
      .then((response) => {
        dispatch({
          type: TYPES.ADD_NEW_BASKET,
          payload: response
        });
        Swal.fire({
          icon: "success",
          title: "Basket created successfully",
          confirmButtonText: "Done"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/basket/");
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_NEW_BASKET_ERROR,
          payload: err?.response?.data || {
            message: "Failed to add new basket"
          }
        });
      });
  };
}

export function editBasket(editedBasket, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.EDIT_BASKET_IN_PROGRESS });
    axiosInstance
      .put(process.env.REACT_APP_ADD_NEW_BASKET + id, editedBasket)
      .then((res) => {
        dispatch({
          type: TYPES.EDIT_BASKET,
          payload: res
        });
        Swal.fire({
          icon: "success",
          title: "Basket updated successfully",
          confirmButtonText: "Done"
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.EDIT_BASKET_ERROR,
          payload: err?.response.data || {
            message: "Failed to update your data"
          }
        });
        Swal.fire({
          title: "Update failed",
          text:
            typeof message === "string"
              ? message
              : "Failed to update your data",
          type: "error"
        });
        if (err.response) {
          throw err.response;
        }
      });
  };
}

export function deleteBasket(id, isRedirect = true) {
  return function (dispatch) {
    axiosInstance
      .delete(process.env.REACT_APP_DELETE_BASKETS_BY_ID + id)
      .then(() => {
        Swal.fire({
          title: "Deleted!",
          text: "Basket deleted!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        }).then(() => {
          dispatch({
            type: TYPES.REMOVE_BASKETS_FROM_LIST,
            payload: [id]
          });
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.DELETE_BASKET_ERROR,
          payload: message || "Failed to delete basket"
        });
        Swal.fire({
          title: "Delete failed",
          text: "basket is already used in one or many strategies",
          icon: "error",
          type: "error"
        });
      });
  };
}

export const deleteBaskets = async (items) => {
  try {
    const response = await axiosInstance.delete(
      `/settings/baskets?items=${items}`
    );
    return response.data;
  } catch (error) {
    return error?.response.data || { error: "Failed to delete baskets" };
  }
};
