import { TYPES } from "./actions";

let initState = {
  frequencies: {},
  frequenciesIsLoading: false,
  benchmarksIsLoading: false,
  benchmarks: [],
  models: [],
  response: {}
};

export function parametersASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_ALL_FREQUENCIES:
      return {
        ...state,
        frequencies: action.payload,
        frequenciesIsLoading: false
      };
    case TYPES.GET_ALL_FREQUENCIES_IN_PROGRESS:
      return {
        ...state,
        frequenciesIsLoading: true
      };

    case TYPES.GET_ALL_BENCHMARKS:
      return {
        ...state,
        benchmarks: action.payload,
        benchmarksIsLoading: false
      };
    case TYPES.GET_ALL_BENCHMARKS_IN_PROGRESS:
      return {
        ...state,
        benchmarksIsLoading: true
      };
    case TYPES.GET_ALL_MODELS:
      return {
        ...state,
        models: action.payload
      };
    default:
      return state;
  }
}
