import { createSlice } from "@reduxjs/toolkit";
import { EDividendFrequencies, EDividendSteps } from "pages/Dividends/types.d";

export interface FormType {
  id?: number;
  amount: number;
  comment: string;
  ex_dividend_date: string;
  ticker: {
    id: number;
    symbol: string;
  };
  frequency: EDividendFrequencies;
}
export interface IDividend {
  step: EDividendSteps;
  data: FormType | number | null;
}

const initialState: IDividend = {
  step: EDividendSteps.FILL,
  data: {
    amount: 0,
    comment: "",
    ex_dividend_date: "",
    ticker: {
      id: -1,
      symbol: ""
    },
    frequency: EDividendFrequencies.MONTHLY
  }
};

export const dividendFormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    initDividend: (state) => {
      state.step = initialState.step;
      state.data = initialState.data;
    },
    updateDividend: (state, action: { type: string; payload: IDividend }) => {
      state.step = action.payload.step;
      state.data = action.payload.data;
    }
  }
});

export const { updateDividend: updateDividendAction, initDividend: initDividendAction } =
  dividendFormSlice.actions;
