import { TYPES } from "./actions";
const initState = {
  connections: [],
  connection: {},
  isLoading: false,
  errors: null
};

export function connectionsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_CONNECTIONS: {
      return {
        ...state,
        connections: action.payload,
        isLoading: false
      };
    }
    case TYPES.GET_CONNECTIONS_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.GET_CONNECTION_BY_ID: {
      return {
        ...state,
        connection: action.payload
      };
    }
    case TYPES.GET_CONNECTIONS_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isLoading: false
      };
    }

    default:
      return state;
  }
}
