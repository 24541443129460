import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

interface Recommendation {
    type: string;
    smartfolio: string;
    group: string;
    description: string;
  }

const initialState = {
    type: "",
    smartfolio: "", // Smartfolio or Ticker
    group: "",
    description: ""
}

const RecommendationSlice = createSlice({
    name: "recommendation",
    initialState,
    reducers: {
        setType: (state, action) => {
            state.type = action.payload;
        },
        setGroup: (state, action) => {
            state.group = action.payload;
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        setSmartfolio: (state, action) => {
            state.smartfolio = action.payload;
        }
    }
});

export const { setSmartfolio, setType, setGroup, setDescription } = RecommendationSlice.actions;

export const selectRecommendationType = createDraftSafeSelector(
    (state: any) => state,
    (state) => state?.type,
  );

export const selectRecommendationDescription = createDraftSafeSelector(
    (state: any) => state,
    (state) => state?.description,
);

export const selectRecommendationGroup = createDraftSafeSelector(
    (state: any) => state,
    (state) => state?.group,
);

export default RecommendationSlice.reducer;