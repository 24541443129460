import { SplitApi } from "redux/api";
import { IEmailTemplate } from 'interfaces/Email';

export const templatesApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTemplates: builder.query<IEmailTemplate[], void>({
      query: () => '/templates',
      providesTags: ['Templates']
    }),
    updateTemplate: builder.mutation<
      Partial<IEmailTemplate>,
      { id: string; body: Partial<Omit<IEmailTemplate, '_id'>> }
    >({
      query: ({ id, body }) => ({
        url: `/templates/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Templates'],
    }),
    deleteTemplate: builder.mutation<string, string>({
      query: (id) => ({
        url: `templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Templates'],
    }),
    createTemplate: builder.mutation<
      Partial<IEmailTemplate>,
      Partial<IEmailTemplate>
    >({
      query: (data) => ({
        url: 'templates',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Templates'],
    }),
  }),
});

export const {
  useGetAllTemplatesQuery,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useCreateTemplateMutation,
} = templatesApi;