import { TYPES } from "./actions";

let initState = {
  comments: {},
  isLoading: false,
  error: {}
};

export function commentsASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.COMMENTS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.GET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
        isLoading: false
      };
    case TYPES.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        isLoading: false
      };
    case TYPES.DELETE_COMMENT:
      return {
        ...state,
        comments: action.payload,
        isLoading: false,
        error: {}
      };
    case TYPES.COMMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
}
