import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";

export const TYPES = {
  GET_FEES: "GET_FEES",
  FEES_IS_LOADING: "FEES_IS_LOADING",
  GET_CLIENT_FEES: "GET_CLIENT_FEES",
  FEES_ERRORS: "FEES_ERRORS",
};

export function getFees() {
  return function (dispatch) {
    dispatch({
      type: TYPES.FEES_IS_LOADING
    })
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_GET_FEES))
      .then((res) => {
        dispatch({
          type: TYPES.GET_FEES,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.FEES_ERRORS,
          payload: err.message,
        });
      });
  };
}

export function getClientFees(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.FEES_ERRORS,
      payload: undefined,
    });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_GET_CLIENT_FEES, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_CLIENT_FEES,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.FEES_ERRORS,
          payload: err.message,
        });
      });
  };
}
