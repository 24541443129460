import { TYPES } from "./actions";

let initState = {
  tickers: [],
  tickersByFields: [],
  favoriteTickers: {},
  tickersLivePrices: [],
  getFavoriteTickersIsLoading: false,
  getTickersByFieldIsLoading: false
};

export function tickerReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_TICKERS: {
      return {
        ...state,
        tickers: action.payload
      };
    }
    case TYPES.GET_FAVORITE_TICKERS_IN_PROGRESS: {
      return {
        ...state,
        getFavoriteTickersIsLoading: true
      };
    }
    case TYPES.GET_TICKERS_LIVE_PRICES: {
      return {
        ...state,
        tickersLivePrices: action.payload
      };
    }
    case TYPES.GET_FAVORITE_TICKERS: {
      return {
        ...state,
        favoriteTickers: action.payload,
        getFavoriteTickersIsLoading: false
      };
    }
    case TYPES.ADD_TICKER_TO_FAVORITE: {
      return {
        ...state,
        favoriteTickers: action.payload
      };
    }
    case TYPES.DELETE_TICKER_FROM_FAVORITE: {
      return {
        ...state,
        favoriteTickers: action.payload
      };
    }
    case TYPES.GET_TICKERS_BY_FIELDS: {
      return {
        ...state,
        tickersByFields: [...action.payload],
        getTickersByFieldIsLoading: false
      };
    }
    case TYPES.GET_TICKERS_BY_FIELDS_IN_PROGRESS: {
      return {
        ...state,
        getTickersByFieldIsLoading: true
      };
    }

    default:
      return state;
  }
}
