import { toast } from "react-toastify";
import Swal from "sweetalert2";

import axiosInstance, { axiosInstanceV2 } from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";

export const TYPES = {
  GET_CLIENTS: "GET_CLIENTS",
  GET_CLIENTS_IN_PROGRESS: "GET_CLIENTS_IN_PROGRESS",
  GET_CLIENTS_BY_MANAGER_ID_IN_PROGRESS: "GET_CLIENTS_BY_MANAGER_ID_IN_PROGRESS",
  CLIENTS_ERROR: "CLIENTS_ERROR",

  GET_CLIENTS_BY_MANAGER_ID: "GET_CLIENTS_BY_MANAGER_ID",
  GET_CLIENTS_BY_MANAGER_ID_ERROR: "GET_CLIENTS_BY_MANAGER_ID_ERROR",

  UPDATE_CLIENT_MANAGER_ID: "UPDATE_CLIENT_MANAGER_ID",

  GET_ACTIVE_CLIENTS: "GET_ACTIVE_CLIENTS",
  GET_ACTIVE_CLIENTS_ERROR: "GET_ACTIVE_CLIENTS_ERROR",

  GET_ONBOARDING_CLIENTS: "GET_ONBOARDING_CLIENTS",
  GET_ONBOARDING_CLIENTS_ERROR: "GET_ONBOARDING_CLIENTS_ERROR",

  GET_REJECTED_AND_DELETED_CLIENTS: "GET_REJECTED_AND_DELETED_CLIENTS",
  GET_REJECTED_AND_DELETED_CLIENTS_ERROR: "GET_REJECTED_AND_DELETED_CLIENTS_ERROR",

  CLIENT_INFO: "CLIENT_INFO",
  CLIENT_INFO_ERROR: "CLIENT_INFO_ERROR",

  CLIENT_ONBOARDING_FORM: "CLIENT_ONBOARDING_FORM",

  CLIENT_UPDATE: "CLIENT_UPDATE",
  CLIENT_UPDATE_ERROR: "CLIENT_UPDATE_ERROR",

  CLIENT_ENABLE: "CLIENT_ENABLE",
  CLIENT_ENABLE_ERROR: "CLIENT_ENABLE_ERROR",

  CLIENT_DISABLE: "CLIENT_DISABLE",
  CLIENT_DISABLE_ERROR: "CLIENT_DISABLE_ERROR",

  CLIENT_APPROVE: "CLIENT_APPROVE",
  CLIENT_APPROVE_ERROR: "CLIENT_APPROVE_ERROR",

  VALIDATE_CLIENT_PASSPORT: "VALIDATE_CLIENT_PASSPORT",
  VALIDATE_CLIENT_PASSPORT_ERROR: "VALIDATE_CLIENT_PASSPORT_ERROR",

  VALIDATE_CLIENT_FILES: "VALIDATE_CLIENT_FILES",
  VALIDATE_CLIENT_FILES_ERROR: "VALIDATE_CLIENT_FILES_ERROR",

  GET_CLIENT_BALANCE: "GET_CLIENT_BALANCE",
  GET_CLIENT_BALANCE_ERROR: "GET_CLIENT_BALANCE_ERROR",
  COMMENT_ERROR: "COMMENT_ERROR",
  COMMENTS: "COMMENTS",
  TOGGLE_CLIENT_COLD: "TOGGLE_CLIENT_COLD",
  GET_COLD_CLIENTS: "GET_COLD_CLIENTS",

  GET_ACTIVE_CLIENTS_BY_MANAGER_ID: "GET_ACTIVE_CLIENTS_BY_MANAGER_ID",
  GET_ACTIVE_CLIENTS_BY_MANAGER_ID_IN_PROGRESS: "GET_ACTIVE_CLIENTS_BY_MANAGER_ID_IN_PROGRESS",
  GET_ACTIVE_CLIENTS_BY_MANAGER_ID_ERROR: "GET_ACTIVE_CLIENTS_BY_MANAGER_ID_ERROR",

  GET_ONBOARDING_CLIENTS_BY_MANAGER_ID: "GET_ONBOARDING_CLIENTS_BY_MANAGER_ID",
  GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_IN_PROGRESS:
    "GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_IN_PROGRESS",
  GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_ERROR: "GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_ERROR"
};

export function getClients() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_CLIENTS_IN_PROGRESS });
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(process.env.REACT_APP_CLIENTS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_CLIENTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getActiveClients() {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(process.env.REACT_APP_ACTIVE_CLIENTS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ACTIVE_CLIENTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ACTIVE_CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getRejectedAndDeletedClients() {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(process.env.REACT_APP_REJECTED_AND_DELETED_CLIENTS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_REJECTED_AND_DELETED_CLIENTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_REJECTED_AND_DELETED_CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getOnBoardingClients() {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(process.env.REACT_APP_ONBOADDING_CLIENTS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ONBOARDING_CLIENTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ONBOARDING_CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getClient(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    if (id)
      return axiosInstanceV2
        .get(urlPrepare("/users/:id", { id }))
        .then((res) => {
          dispatch({
            type: TYPES.CLIENT_INFO,
            payload: res.data
          });
          dispatch({
            type: TYPES.COMMENTS,
            payload: undefined
          });
          return res.data;
        })
        .catch((err) => {
          dispatch({
            type: TYPES.CLIENTS_ERROR,
            payload: err.message
          });
        });
  };
}

export function getClientV1(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    if (id)
      return axiosInstance
        .get(urlPrepare("/clients/:id/fullinfo", { id }))
        .then((res) => {
          dispatch({
            type: TYPES.CLIENT_INFO,
            payload: res.data
          });
          dispatch({
            type: TYPES.COMMENTS,
            payload: undefined
          });
          return res.data;
        })
        .catch((err) => {
          dispatch({
            type: TYPES.CLIENTS_ERROR,
            payload: err.message
          });
        });
  };
}

export function getActiveClientsByManagerId(managerId) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ACTIVE_CLIENTS_BY_MANAGER_ID_IN_PROGRESS });
    axiosInstance
      // Quick Fix
      // REACT_APP_ACTIVE_CLIENTS_BY_MANAGER_ID
      .get(`${process.env.REACT_APP_ACTIVE_CLIENTS}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ACTIVE_CLIENTS_BY_MANAGER_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ACTIVE_CLIENTS_BY_MANAGER_ID_ERROR,
          payload: err.message
        });
      });
  };
}

export function getOnboardingClientsByManagerId(managerId) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_IN_PROGRESS });
    axiosInstance
      .get(`${process.env.REACT_APP_ONBOARDING_CLIENTS_BY_MANAGER_ID}?managerId=${managerId}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ONBOARDING_CLIENTS_BY_MANAGER_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_ERROR,
          payload: err.message
        });
      });
  };
}

//update client manager
export function updateClientManagerId(clientId, managerId, newManagerId) {
  return async function (dispatch) {
    return axiosInstance
      .put(process.env.REACT_APP_UPDATE_CLIENT_MANAGER_ID, {
        clientId: clientId,
        managerId: managerId,
        newManagerId: newManagerId
      })
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_CLIENT_MANAGER_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function addClient(data) {
  return async function () {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_ADD_CLIENT), data, {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        return response.data._id;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function enableClient(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_CLIENT_ENABLE, { id }))
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function disableClient(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_CLIENT_DISABLE, { id }))
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function deleteClient(id) {
  return function () {
    return axiosInstance.delete(urlPrepare(process.env.REACT_APP_DELETE_CLIENT, { id }));
  };
}

export function approveClient(id) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CLIENT_APPROVE, { id }))
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function uploadFiles(id, data) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    const formData = new FormData();
    const { note, ...files } = data;

    Object.keys(files).forEach((name) => {
      for (let i = 0; i < files[name].length; i++) {
        formData.append(name, files[name][i]);
      }
    });

    formData.append("note", note);

    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CLIENT_UPLOAD, { id }), formData).then(() => {
        toast.success("File(s) uploaded successfully!")
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function uploadPassport(id, files) {
  return async function () {
    const { passport } = files;
    const formData = new FormData();
    formData.append("passport", passport);

    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CLIENT_PASSPORT_UPLOAD, { id }), formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function editClientType(id, formData) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CLIENT_EDIT_TYPE, { id }), formData, {
        headers: { "Content-Type": "application/json" }
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function editClientRiskGrade(id, formData) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CLIENT_EDIT_RISKGRADE, { id }), formData, {
        headers: { "Content-Type": "application/json" }
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function updateAdminForm(id, formData) {
  return async function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_UPDATE_ADMIN_FORM, { id }), formData, {
        headers: { "Content-Type": "application/json" }
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function addComment(comment, id) {
  return async function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_ADD_COMMENT, { id }), { comment })
      .then((adminForm) => {
        dispatch({
          type: TYPES.COMMENTS,
          payload: adminForm.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.COMMENT_ERROR,
          payload: err.message
        });
        throw err;
      });
  };
}

export function updateComplianceSteps(steps, id) {
  return async function (dispatch) {
    try {
      const res = await axiosInstance.post(
        urlPrepare(process.env.REACT_APP_UPDATE_COMPLIANCE_STEPS, { id }),
        steps
      );

      dispatch({
        type: TYPES.CLIENT_ONBOARDING_FORM,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: TYPES.CLIENTS_ERROR,
        payload: err.message
      });
      console.error(err, "ERROR");
      throw err;
    }
  };
}

export function deleteComment(commentId, id) {
  return async function (dispatch) {
    const { data: adminform } = await axiosInstance.put(
      urlPrepare(process.env.REACT_APP_DELETE_COMMENT, { id }),
      { commentId }
    );
    dispatch({
      type: TYPES.COMMENTS,
      payload: adminform.comments
    }).catch((err) => {
      dispatch({
        type: TYPES.COMMENT_ERROR,
        payload: err.message
      });
      throw err;
    });
  };
}

export function updateClientProfile(id, formData) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_UPDATE_PROFILE, { id }), formData, {
        headers: { "Content-Type": "application/json" }
      })
      .catch((err) => {
        toast.error("Failed To update client Profile", err.message);
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
        throw err;
      });
  };
}

export function emailAdminForm(id, formData) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_EMAIL_ADMIN_FORM, { id }), formData, {
        headers: { "Content-Type": "application/json" }
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
        throw err;
      });
  };
}

export function resetClientPassword(id, body) {
  return async function () {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_CLIENT_RESET_PASSWORD, { id }), body)
      .catch((err) => {
        throw err;
      });
  };
}

export function validateClientFiles(clientId) {
  return function (dispatch) {
    return axiosInstance
      .post(`${process.env.REACT_APP_API_HOST}/clients/${clientId}/updateOnboardingStep`, {
        step: "PROOF_OF_ADDRESS"
      })
      .then((res) => {
        dispatch({
          type: TYPES.VALIDATE_CLIENT_FILES,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "Client Files Validated Successfully",
          confirmButtonText: "Ok, got it!"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.VALIDATE_CLIENT_FILES_ERROR,
          payload: err
        });
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          confirmButtonText: "Ok, got it!"
        });
      });
  };
}

export function toggleClientCold(id, isCold) {
  return async function (dispatch) {
    return axiosInstance
      .put(`${process.env.REACT_APP_CLIENTS}/${id}/toggle-cold`, {
        isCold: isCold
      })
      .then((response) => {
        dispatch({
          type: TYPES.TOGGLE_CLIENT_COLD,
          payload: isCold
        });
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getColdClients() {
  return async function (dispatch) {
    return axiosInstance
      .get(process.env.REACT_APP_COLD_CLIENTS)
      .then((response) => {
        dispatch({
          type: TYPES.GET_COLD_CLIENTS,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: undefined
        });
      });
  };
}

export function enableClientStatus(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_CLIENT_ENABLE_STATUS, { id }))
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function disableClientStatus(id) {
  return function (dispatch) {
    dispatch({
      type: TYPES.CLIENTS_ERROR,
      payload: undefined
    });
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_CLIENT_DISABLE_STATUS, { id }))
      .catch((err) => {
        dispatch({
          type: TYPES.CLIENTS_ERROR,
          payload: err.message
        });
      });
  };
}
