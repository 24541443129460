import { TYPES } from "./actions";

let initState = {
  backTests: [],
  backTest: {},

  getAllBackTestsIsLoading: false,
  getBackTestIsLoading: false,
  createBackTestIsLoading: false,
  deleteBackTestIsLoading: false,

  getAllBackTestsError: null,
  getBackTestError: null,
  createBackTestError: null,
  deleteBackTestError: null
};

export function backtestASReducer(state = initState, action) {
  switch (action.type) {
    // Get All BackTests
    case TYPES.GET_ALL_BACKTESTS:
      return {
        ...state,
        backTests: action.payload,
        getAllBackTestsIsLoading: false
      };
    case TYPES.GET_ALL_BACKTESTS_IN_PROGRESS:
      return {
        ...state,
        getAllBackTestsIsLoading: true
      };
    case TYPES.GET_ALL_BACKTESTS_ERROR:
      return {
        ...state,
        getAllBackTestsError: action.payload,
        getAllBackTestsIsLoading: false
      };

    // Get BackTest By Id
    case TYPES.GET_BACKTEST_BY_ID:
      return {
        ...state,
        backTest: action.payload,
        getBackTestIsLoading: false
      };
    case TYPES.GET_BACKTEST_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getBackTestIsLoading: true
      };
    case TYPES.GET_BACKTEST_BY_ID_ERROR:
      return {
        ...state,
        getBackTestError: action.payload,
        getBackTestIsLoading: false
      };

    // Create BackTest
    case TYPES.CREATE_BACKTEST:
      return {
        ...state,
        backTest: action.payload,
        createBackTestIsLoading: false
      };
    case TYPES.CREATE_BACKTEST_IN_PROGRESS:
      return {
        ...state,
        createBackTestIsLoading: true
      };
    case TYPES.CREATE_BACKTEST_ERROR:
      return {
        ...state,
        createBackTestError: action.payload,
        createBackTestIsLoading: false
      };

    // Delete BackTest
    case TYPES.DELETE_BACKTEST:
      // Remove deleted BackTest From The List
      const newBackTests = state.backTests.filter((backTest) => {
        return !action?.payload?.includes(backTest?._id);
      });
      return {
        ...state,
        backTests: newBackTests,
        deleteBackTestIsLoading: false
      };
    case TYPES.DELETE_BACKTEST_IN_PROGRESS:
      return {
        ...state,
        deleteBackTestIsLoading: true
      };
    case TYPES.DELETE_BACKTEST_ERROR:
      return {
        ...state,
        deleteBackTestError: action.payload,
        deleteBackTestIsLoading: false
      };
    default:
      return state;
  }
}
