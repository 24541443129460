import { TYPES } from "./actions";
const initState = {
  rebalancingRequest: {},
  rebalancingLoading: false
};

export function rebalancingRequestsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_REBALANCING_REQUQESTS: {
      return {
        ...state,
        list: action.payload
      };
    }
    case TYPES.CREATE_REBALANCING_REQUQEST: {
      return {
        ...state,
        list: {
          values: [action.payload, ...(state.list.values || [])],
          count: (state.list.count || 0) + 1
        }
      };
    }
    case TYPES.GET_REBALANCING_REQUQEST: {
      return {
        ...state,
        rebalancingRequest: action.payload
      };
    }
    case TYPES.REBALANCING_LOADING: {
      return {
        ...state,
        rebalancingLoading: action.payload
      };
    }
    case TYPES.REBALANCINGS_LOADING: {
      return {
        ...state,
        rebalancingsLoading: action.payload
      };
    }

    default:
      return state;
  }
}
