import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
  GET_CONNECTIONS: "GET_CONNECTIONS",
  GET_CONNECTIONS_IN_PROGRESS: "GET_CONNECTIONS_IN_PROGRESS",
  GET_CONNECTIONS_ERROR: "GET_CONNECTIONS_ERROR",

  GET_CONNECTION_BY_ID: "GET_CONNECTION_BY_ID",
  GET_CONNECTION_BY_ID_ERROR: "GET_CONNECTION_BY_ID_ERROR"
};

export function getConnections(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_CONNECTIONS_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_CONNECTIVITY + id)
      .then((res) => {
        dispatch({
          type: TYPES.GET_CONNECTIONS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_CONNECTIONS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getConnectionById(userId, id) {
  return function (dispatch) {
    axiosInstance
      .get(`${process.env.REACT_APP_CONNECTIVITY}${userId}/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_CONNECTION_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_CONNECTION_BY_ID_ERROR,
          payload: err.message
        });
      });
  };
}
