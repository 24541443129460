import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const OrdersSummary = SplitApiV2.injectEndpoints({
    endpoints: (builder) => ({
        getOrdersSummary: builder.query<any, any>({
            query: ({ pageNumber, pageSize, date, status }) => {
                let queryParams = `/reports/activities-summary?created_at=${date}&page_number=${pageNumber}&page_size=${pageSize}`;
                if (status && status !== 'ALL') {
                    queryParams += `&operation_type=${status}`;
                }
                return urlPrepare(queryParams)
            },
            providesTags: ["OrdersSummary"]
        }),
    })
});

export const {
    useGetOrdersSummaryQuery
} = OrdersSummary;
