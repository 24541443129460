import { TYPES } from "./actions";

let initState = {
  basket: {},
  baskets: [],
  response: {},
  addBasketRes: {},
  basketsWithSentiments: [],
  editBasketRes: {},
  deletBasketRes: {},
  error: null,
  getBasketByIdIsLoading: false,
  createBasketIsLoading: false,
  editBasketIsLoading: false,
  isLoading: false
};

export function basketASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.ADD_NEW_BASKET_IN_PROGRESS:
      return {
        ...state,
        createBasketIsLoading: true
      };
    case TYPES.GET_BASKET_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getBasketByIdIsLoading: true
      };
    case TYPES.EDIT_BASKET_IN_PROGRESS:
      return {
        ...state,
        editBasketIsLoading: true
      };
    case TYPES.GET_ALL_BASKETS:
      return {
        ...state,
        baskets: action.payload,
        isLoading: false
      };
    case TYPES.ADD_ALL_BASKETS_WITH_SENTIMENTS:
      return {
        ...state,
        basketsWithSentiments: action.payload
      };
    case TYPES.GET_ALL_BASKETS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case TYPES.BASKET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case TYPES.GET_BASKET_BY_ID:
      return {
        ...state,
        basket: action.payload,
        getBasketByIdIsLoading: false
      };
    case TYPES.ADD_NEW_BASKET:
      return {
        ...state,
        addBasketRes: action.payload,
        createBasketIsLoading: false,
        error: null
      };
    case TYPES.ADD_NEW_BASKET_ERROR:
      return {
        ...state,
        error: action.payload,
        createBasketIsLoading: false
      };
    case TYPES.EDIT_BASKET:
      return {
        ...state,
        editBasketRes: action.payload,
        createBasketIsLoading: false,
        editBasketIsLoading: false
      };
    case TYPES.EDIT_BASKET_ERROR:
      return {
        ...state,
        error: action.payload,
        editBasketIsLoading: false
      };
    case TYPES.REMOVE_BASKETS_FROM_LIST: {
      const newBaskets = state.baskets.filter((basket) => {
        return !action?.payload?.includes(basket._id);
      });
      return {
        ...state,
        baskets: newBaskets
      };
    }
    default:
      return state;
  }
}
