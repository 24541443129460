import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const usersApi = SplitApiV2.injectEndpoints({
    endpoints: (builder) => ({
        getAllUsers: builder.query<any, any>({
            query: ({pageNumber, pageSize}) => ({
              url: urlPrepare(`/users?page_number=${pageNumber}&page_size=${pageSize}`),
            }),
            providesTags: ["Users"]
        }),
        getUsers: builder.query<any, any>({
            query: ({pageNumber, pageSize}) => ({
              url: urlPrepare(`/managers/?page_number=${pageNumber}&page_size=${pageSize}`),
            }),
            providesTags: ["Users"]
        }),
        getUserById: builder.query<any, any>({
            query: ({id}) => ({
                url: urlPrepare(`/managers/${id}`),
            }),
            providesTags: ["Users"]
        }),
        getUsersByRole: builder.query<any, any>({
            query: (id) => ({
                url: urlPrepare(`/${id}`),
            }),
            providesTags: ["Users"]
        }),
        addUser: builder.mutation<any, any>({
            query: (payload) => ({
                url: urlPrepare(`/managers`),
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Users"]
        }),
        updateUser: builder.mutation<any, any>({
            query: ({id, payload}) => ({
                url: urlPrepare(`/managers/${id}`),
                method: "PUT",
                body: payload
            }),
            invalidatesTags: ["Users"]
        }),
    })
});

export const {
    useGetAllUsersQuery,
    useGetUsersQuery,
    useGetUserByIdQuery,
    useGetUsersByRoleQuery,
    useAddUserMutation,
    useUpdateUserMutation,
  } = usersApi;