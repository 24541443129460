import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
  GET_COMMENTS: "GET_COMMENTS",
  ADD_COMMENT: "ADD_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  COMMENTS_IN_PROGRESS: "COMMENTS_IN_PROGRESS",

  COMMENTS_ERROR: "COMMENTS_ERROR"
};

export function getComments(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.COMMENTS_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_API_HOST + `/comments/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_COMMENTS,
          payload: res.data[0]
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.COMMENTS_ERROR,
          payload: err.message
        });
      });
  };
}

export function addComment(values) {
  return function (dispatch) {
    dispatch({ type: TYPES.COMMENTS_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_API_HOST + `/newComment/`, values)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_COMMENT,
          payload: res.data
        });
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || "Failed to add new comment";
        dispatch({
          type: TYPES.COMMENTS_ERROR,
          payload: message
        });
      });
  };
}

export function deleteComment(values) {
  return function (dispatch) {
    dispatch({ type: TYPES.COMMENTS_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_API_HOST + `/deleteComment/`, values)
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_COMMENT,
          payload: res.data
        });
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || "Failed to delete comment";
        dispatch({
          type: TYPES.COMMENTS_ERROR,
          payload: message
        });
      });
  };
}
