import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const currentUserApi = SplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query<any, any>({
            query: () => ({
                url: urlPrepare(`/users/me`),
            }),
            providesTags: ["CurrentUser"]
        }),
        refreshToken: builder.query<any, any>({
            query: () => ({
                url: "/auth/token/refresh",
            }),
            providesTags: ["CurrentUser"]
        })
    })
});

export const {
    useGetMeQuery,
    useRefreshTokenQuery
} = currentUserApi