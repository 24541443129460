import axiosInstance, { axiosInstanceV2 } from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import {
  handleRebalancingSummary,
  handleOrderSummary,
  handleExitSummary
} from "../../utils/activitiesSummary";
import dayjs from "dayjs";

export const TYPES = {
  GET_ORDERS_SUMMARY: "GET_ORDERS_SUMMARY",
  IS_LOADING: "IS_LOADING",
  GET_ORDERS_SUMMARY_ERROR: "GET_ORDERS_SUMMARY_ERROR"
};

export function getOrdersSummary(date) {
  return function (dispatch) {
    dispatch({
      type: TYPES.IS_LOADING,
      payload: true
    });
    dispatch({
      type: TYPES.GET_ORDERS_SUMMARY_ERROR,
      payload: undefined
    });

    const targetDate = date ??  dayjs().format('YYYY-MM-DD');

    return axiosInstanceV2
      .get(urlPrepare(process.env.REACT_APP_GET_ACTIVITIES_SUMMARY) + `?date=${targetDate}`)
      .then((res) => {
        const responseDataKeys = Object.keys(res.data);

        const ordersSummary = responseDataKeys.map((key) => {
          const orderType = key.replace("Summary", "");

          if (orderType === "rebalancings")
            return handleRebalancingSummary(res.data[key], orderType);
          return handleOrderSummary(res.data[key], orderType);
        });

        const exitsSummary = handleExitSummary(res.data["investmentsSummary"]);

        ordersSummary.push({
          name: "exits",
          ...exitsSummary
        });

        const ordersList = ordersSummary.reduce((acc, curr) => [...acc, ...curr.ordersList], []);

        dispatch({
          type: TYPES.GET_ORDERS_SUMMARY,
          payload: { ordersSummary, ordersList }
        });

        dispatch({
          type: TYPES.IS_LOADING,
          payload: false
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ORDERS_SUMMARY_ERROR,
          payload: err.message
        });
      });
  };
}
