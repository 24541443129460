import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import Swal from "sweetalert2";

export const TYPES = {
  GET_USER_ROLES: "GET_USER_ROLES",
  GET_USER_ROLES_IN_PROGRESS: "GET_USER_ROLES_IN_PROGRESS",
  ADD_ROLE: "ADD_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",
  GET_ROLE_DETAILS: "GET_ROLE_DETAILS",
  GET_ROLE_DETAILS_IN_PROGRESS: "GET_ROLE_DETAILS_IN_PROGRESS",
  UPDATE_PERMISSION: "UPDATE_PERMISSION",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_ERROR: "DELETE_ROLE_ERROR"
};

export function getUserRoles() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_USER_ROLES_IN_PROGRESS });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_USER_ROLES))
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER_ROLES,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function addRole(newRole) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_ADD_ROLE), newRole)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_ROLE,
          payload: res.data,
        });
        Swal.fire({
          icon: 'success',
          title: 'Added!',
          text: res.data.message,
        })
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            title: 'Failure!',
            text: err.response.data.message,
            cancelButtonText: "Cancel"
          });
        }
      });
  };
}

export function updateRole(updatedRole) {
  return function (dispatch) {
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_UPDATE_ROLE), updatedRole)
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_ROLE,
          payload: res.data,
        });
        Swal.fire({
          icon: 'success',
          title: 'Updated!',
          text: res.data.message,
        });
      })
      .catch((err) => {
        if (err.response) {
          Swal.fire({
            icon: "error",
            title: "Failure!",
            text: err.response.data.message,
            cancelButtonText: "Cancel"
          });
        }
      });
  };
}

export function getPermissions() {
  return function (dispatch) {
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_USER_PERMISSIONS))
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER_PERMISSIONS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getRoleDetails(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ROLE_DETAILS_IN_PROGRESS });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_GET_ROLE_DETAILS, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_ROLE_DETAILS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updatePermission(updatedPermission) {
  return function (dispatch) {
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_UPDATE_PERMISSION), updatedPermission)
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_PERMISSION,
          payload: res.data,
        });
        Swal.fire({
          icon: 'success',
          title: 'Updated!',
          text: res.data.message,
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

//Note: not used
export function deleteRole(roleId) {
  return function (dispatch) {
    axiosInstance
      .delete(process.env.REACT_APP_DELETE_ROLE + roleId)
      .then(() => {
        dispatch(getUserRoles());
        Swal.fire({
          title: "Deleted!",
          text: "Role deleted successfully!",
          icon: "success",
        }).then(() => {
          dispatch({
            type: TYPES.DELETE_ROLE,
            payload: [roleId]
          });
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.DELETE_ROLE_ERROR,
          payload: message || "Failed to delete role"
        });
        Swal.fire({
          title: "Failure!",
          icon: "error",
          cancelButtonText: "Cancel",
          text:
            typeof message === "string" ? message : "Failed to delete role",
          type: "error"
        });
      });
  };
}
