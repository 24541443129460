import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const recommendationApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getRecommendations: builder.query<any, any>({
      query: ({pageNumber, pageSize}) =>
        urlPrepare(`/recommendations/?page_number=${pageNumber}&page_size=${pageSize}`),
      providesTags: ["recommendations"]
    }),
    getRecommendationById: builder.query<any, any>({
      query: (id) => urlPrepare(`/recommendations/${id}`),
      providesTags: ["recommendations"]
    }),
    getSmartofolioNav: builder.query<any, any>({
      query: (id) => urlPrepare(`/smartfolios/${id}/nav`),
      providesTags: ["recommendations"]
    }),
    createRecommendation: builder.mutation<any, any>({
      query: (body) => ({
        url: urlPrepare(`/recommendations`),
        method: "POST",
        body
      }),
      invalidatesTags: ["recommendations"]
    }),
    updateRecommendation: builder.mutation<any, any>({
        query: ({id, body}) => ({
          url: urlPrepare(`/recommendations/${id}`),
          method: "PUT",
          body
        }),
        invalidatesTags: ["recommendations"]
      }),
    deleteRecommendation: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(`/recommendations/${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["recommendations"]
    }),
  })
});

export const {
  useGetRecommendationsQuery,
  useGetRecommendationByIdQuery,
  useGetSmartofolioNavQuery,
  useCreateRecommendationMutation,
  useUpdateRecommendationMutation,
  useDeleteRecommendationMutation
} = recommendationApi;
