import axios from "axios";
import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    GET_ME: 'GET_ME'
}

export function getMe() {
  return function(dispatch) {
    return axiosInstance.get(process.env.REACT_APP_USER_ME)
      .then(res => {
        dispatch({
          type: TYPES.GET_ME,
          payload: res.data
        });
        return res.data
      })
      .catch(err => {
        if(err.response) {
          throw err.response;
        }else {
          // to be handled internal server error
        }
      });
  };
}

export function userLogin({ email, password }) {
  return function(dispatch) {
    return axios.post(process.env.REACT_APP_API_HOST + process.env.REACT_APP_USER_LOGIN, { email, password })
      .then(res => {
        dispatch({
          type: TYPES.LOGIN_SUCCESS,
          payload: res.data
        });
        // Set token in headers
        localStorage.setItem('token', res.data.token)
        return res.data
      })
      .catch(err => {
        if(err.response) {
          throw err.response;
        }else {
          // to be handled internal server error
        }
      });
  };
}

export function userLogout() {
  return function(dispatch) {
    localStorage.removeItem('token');
    dispatch({
      type: TYPES.LOGOUT
    });
  }
}

