import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
    GET_ALL_GROUPS: "GET_ALL_GROUPS",
    GET_ALL_GROUPS_ERROR: "GET_ALL_GROUPS_ERROR",

    GET_GROUP_BY_ID: "GET_GROUP_BY_ID",
    GET_GROUP_BY_ID_ERROR: "GET_GROUP_BY_ID_ERROR",

    GET_GROUP_BY_MANAGER_ID: "GET_GROUP_BY_MANAGER_ID",
    GET_GROUP_BY_MANAGER_ID_ERROR: "GET_GROUP_BY_MANAGER_ID_ERROR",

    ADD_NEW_GROUP: "ADD_NEW_GROUP",
    ADD_NEW_GROUP_ERROR: "ADD_NEW_GROUP_ERROR",

    UPDATE_GROUP: "UPDATE_GROUP",
    UPDATE_GROUP_ERROR: "UPDATE_GROUP_ERROR",

    DELETE_GROUP: "DELETE_GROUP",
    DELETE_GROUP_ERROR: "DELETE_GROUP_ERROR",
};

export function getAllGroups() {
  return function (dispatch) {
    axiosInstance
      .get(process.env.REACT_APP_PAM_GROUPS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_GROUPS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_GROUPS_ERROR,
          payload: err,
        });
      });
  };
}

export function getGroupById(id) {
  return function (dispatch) {
    axiosInstance
      .get(process.env.REACT_APP_PAM_GROUPS + id )
      .then((res) => {
        dispatch({
          type: TYPES.GET_GROUP_BY_ID,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_GROUP_BY_ID_ERROR,
          payload: err,
        });
      });
  };
}

export function getGroupByManagerId(id) {
  return function (dispatch) {
    axiosInstance
      .get("/pam/manager/" + id )
      .then((res) => {
        dispatch({
          type: TYPES.GET_GROUP_BY_MANAGER_ID,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_GROUP_BY_MANAGER_ID_ERROR,
          payload: err,
        });
      });
  };
}

export function addNewGroup(newGroup) {
  return function (dispatch) {
    return axiosInstance
      .post(process.env.REACT_APP_PAM_GROUPS, newGroup)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_NEW_GROUP,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          title: "Your work has been saved",
          confirmButtonText: "Ok, got it!",
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.reload();
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_NEW_GROUP_ERROR,
          payload: err,
          });
          Swal.fire({
            icon: "error",
            title: "This manager has already a Group",
            confirmButtonText: "Ok, got it!",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
            }
          });
      });
  };
}

export function  updateGroup(id, newData) {
  return function (dispatch) {
    return axiosInstance
      .put(process.env.REACT_APP_PAM_GROUPS + id , newData)
      .then((res) => {
        dispatch({
          type: TYPES.UPDATE_GROUP,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          title: "Your work has been saved",
          confirmButtonText: "Ok, got it!",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.UPDATE_GROUP_ERROR,
          payload: err,
        });
        Swal.fire({
          icon: "error",
          title: "This manager has already a Group",
          confirmButtonText: "Ok, got it!",
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.reload();
          }
        });
      });
  };
}

export function deleteGroup(id) {
  return function (dispatch) {
    return axiosInstance
      .delete(process.env.REACT_APP_PAM_GROUPS + id )
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_GROUP,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DELETE_GROUP_ERROR,
          payload: err,
        });
      });
  };
}