import axiosInstance from '../../helpers/axiosInstance'

export const TYPES = {
  ADD_FEE: 'ADD_FEE',
  GET_FEES: 'GET_FEES',
  UPDATE_FEE: 'UPDATE_FEE',
  GET_HISTORY: 'GET_HISTORY',
}

export function getFees() {
  return async dispatch => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BROKER_FEES_ROUTE}`,
      )

      dispatch({
        type: TYPES.GET_FEES,
        payload: data,
      })
    } catch (error) {
      throw Error("can't get broker fees")
    }
  }
}

export function updateFee(tickerId, { minCost, percentage }) {
  return async dispatch => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_BROKER_FEES_ROUTE}/${tickerId}`,
        {
          minCost,
          percentage,
        },
      )

      dispatch({
        type: TYPES.UPDATE_FEE,
        payload: {
          tickerId,
          minCost,
          percentage,
        },
      })
    } catch (error) {
      throw Error("can't update fee")
    }
  }
}

export function getHistory(tickerId) {
  return async dispatch => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BROKER_FEES_ROUTE}/history/${tickerId}`,
      )

      dispatch({
        type: TYPES.GET_HISTORY,
        payload: data,
      })
    } catch (error) {
      throw Error("can't  get history")
    }
  }
}
