import { TYPES } from "./actions";
let initState = {
  statements: [],
  loadingStatements: false,
  errors: undefined,
};

export const statementsReducer = (state = initState, action) => {
  switch (action.type) {
    case TYPES.GET_STATEMENTS: {
      return {
        ...state,
        statements: action.payload,
        loadingStatements: false
      };
    }
    case TYPES.STATEMENTS_LOADING: {
      return {
        ...state,
        loadingStatements: true,
      };
    }
    case TYPES.STATEMENTS_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }
    default:
      return state;
  }
};

