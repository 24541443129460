import { TYPES } from "./actions";

let initState = {
  investments: [],
  exitResponse: undefined,
  cancelResponse: undefined,
  clientInvestments: [],
  deposits: [],
  clientDeposits: [],
  withdraws: [],
  clientWithdraws: [],
  order: null,
  error: "",
  errors: undefined,
  isLoading: false,
  snapshots: [],
  withdrawals: [],
  depositsOrders: [],
  snapshotsIsLoading: false
};

export function orderReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_INVESTMENTS: {
      return {
        ...state,
        investments: action.payload
      };
    }
    case TYPES.EXIT_FROM_INVESTMENT: {
      return {
        ...state,
        exitResponse: action.payload
      };
    }
    case TYPES.EXIT_FROM_INVESTMENT_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TYPES.CANCEL_INVESTMENT: {
      return {
        ...state,
        cancelResponse: action.payload
      };
    }
    case TYPES.CANCEL_INVESTMENT_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TYPES.IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case TYPES.GET_DEPOSITS: {
      return {
        ...state,
        deposits: action.payload
      };
    }

    case TYPES.GET_WITHDRAWS: {
      return {
        ...state,
        withdraws: action.payload
      };
    }

    case TYPES.GET_WITHDRAWALS: {
      return {
        ...state,
        withdrawals: action.payload
      };
    }
    case TYPES.GET_ORDERS_DEPOSITS: {
      return {
        ...state,
        depositsOrders: action.payload
      };
    }
    case TYPES.GET_CLIENT_INVESTMENTS: {
      return {
        ...state,
        clientInvestments: action.payload
      };
    }

    case TYPES.GET_CLIENT_DEPOSITS: {
      return {
        ...state,
        clientDeposits: action.payload
      };
    }

    case TYPES.GET_CLIENT_WITHDRAWS: {
      return {
        ...state,
        clientWithdraws: action.payload
      };
    }

    case TYPES.GET_ORDER: {
      return {
        ...state,
        order: action.payload
      };
    }
    case TYPES.GET_ORDER_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TYPES.GET_ORDERS_ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case TYPES.GET_INVESTMENTS_SNAPSHOTS: {
      return {
        ...state,
        snapshots: action.payload,
        snapshotsIsLoading: false
      };
    }
    case TYPES.GET_INVESTMENTS_SNAPSHOTS_IN_PROGRESS: {
      return {
        ...state,
        snapshotsIsLoading: true
      };
    }
    case TYPES.GET_INVESTMENTS_SNAPSHOTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        snapshotsIsLoading: false
      };
    }
    default:
      return state;
  }
}
