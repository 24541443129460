import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import Swal from "sweetalert2";
export const TYPES = {
  GET_BANK_ACCOUNTS: "GET_BANK_ACCOUNTS",
  CREATE_BANK_ACCOUNT: "CREATE_BANK_ACCOUNT",
};

export function getClientBankAccounts(id) {
  return function (dispatch) {
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_BANK_ACCOUNT, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_BANK_ACCOUNTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createBankAccount(id, formData) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_BANK_ACCOUNT, { id }), formData)
      .then((res) => {
        dispatch({
          type: TYPES.CREATE_BANK_ACCOUNT,
          payload: res.data,
        });
        Swal.fire({
          icon: 'success',
          title: 'Bank Account created successfully',
          showCancelButton: false,
          showConfirmButton: false
        })
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'IBAN already exists',
          showCancelButton: false,
          showConfirmButton: false
        })
      });
  };
}
