import { TYPES } from "./actions";

let initState = {
  solutions: [],
  solution: null,
  response: {},
  addSolutionRes: {},
  createNewSolutionIsLoading: false,
  editSolutionIsLoading: false,
  editSolutionRes: {},
  deleteSolutionRes: {},
  isLoading: false,
  getSolutionByIdInProgress: false
};

export function solutionASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.ADD_NEW_SOLUTION_IN_PROGRESS: {
      return {
        ...state,
        createNewSolutionIsLoading: true
      };
    }
    case TYPES.EDIT_SOLUTION_IN_PROGRESS: {
      return {
        ...state,
        editSolutionIsLoading: true
      };
    }
    case TYPES.GET_NEWS_SOLUTIONS_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.GET_ALL_SOLUTIONS:
      return {
        ...state,
        solutions: action.payload,
        isLoading: false
      };
    case TYPES.GET_SOLUTION_BY_ID:
      return {
        ...state,
        solution: action.payload,
        getSolutionByIdInProgress: false
      };
    case TYPES.GET_SOLUTION_BY_ID_ERROR:
      return {
        ...state,
        getSolutionByIdInProgress: false
      };
    case TYPES.GET_SOLUTION_BY_ID_IS_LOADING:
      return {
        ...state,
        getSolutionByIdInProgress: true
      };
    case TYPES.ADD_NEW_SOLUTION:
      return {
        ...state,
        addSolutionRes: action.payload,
        createNewSolutionIsLoading: false
      };
    case TYPES.ADD_NEW_SOLUTION_ERROR:
      return {
        ...state,
        createNewSolutionIsLoading: false
      };
    case TYPES.EDIT_SOLUTION:
      return {
        ...state,
        editSolutionRes: action.payload,
        editSolutionIsLoading: false
      };
    case TYPES.EDIT_SOLUTION_ERROR:
      return {
        ...state,
        editSolutionIsLoading: false
      };
    case TYPES.DELET_SOLUTION:
      return {
        ...state,
        deleteSolutionRes: action.payload
      };
    case TYPES.REMOVE_SOLUTIONS_FROM_LIST:
      const newSolution = state.solutions?.filter(
        (solution) => !action?.payload?.includes(solution._id)
      );
      return {
        ...state,
        solutions: newSolution
      };
    default:
      return state;
  }
}
