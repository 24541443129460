import { TYPES } from "./actions";

 const initialStates = {
    isDarkMode: false
}

export const themeReducer = (state = initialStates, action) => {
    switch (action.type) {
        case TYPES.CHANGE_MODE: {
            return {
                ...state,
                isDarkMode: action.payload
            }
        }
        default :
            return state
    }
}