import axiosInstance from "../../helpers/axiosInstance";
import { toast } from "react-toastify";

export const TYPES = {
  GET_TICKERS: "GET_TICKERS",
  GET_TICKERS_LIVE_PRICES: "GET_TICKERS_LIVE_PRICES",
  GET_FAVORITE_TICKERS_IN_PROGRESS: "GET_FAVORITE_TICKERS_IN_PROGRESS",

  GET_TICKERS_BY_FIELDS: "GET_TICKERS_BY_FIELDS",
  GET_TICKERS_BY_FIELDS_IN_PROGRESS: "GET_TICKERS_BY_FIELDS_IN_PROGRESS",
  GET_FAVORITE_TICKERS: "GET_FAVORITE_TICKERS",
  ADD_TICKER_TO_FAVORITE: "ADD_TICKER_TO_FAVORITE",
  DELETE_TICKER_FROM_FAVORITE: "DELETE_TICKER_FROM_FAVORITE",
  TICKERS_ERROR: "TICKERS_ERROR"
};

export function getTickers() {
  return function (dispatch) {
    axiosInstance
      .get("/tickers/light")
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKERS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}

export function addFavorite(symbol) {
  return function (dispatch) {
    axiosInstance
      .post("/addFavouriteTicker", { symbol: symbol })
      .then((res) => {
        dispatch({
          type: TYPES.ADD_TICKER_TO_FAVORITE,
          payload: res.data
        });
      })
      .then(() => {
        toast.info(`${symbol} added to your watchlist`);
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getFavoriteTickers() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_FAVORITE_TICKERS_IN_PROGRESS });
    axiosInstance
      .get("/getFavoriteTickers")
      .then((res) => {
        dispatch({
          type: TYPES.GET_FAVORITE_TICKERS,
          payload: res?.data === "" || !res?.data ? [] : res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}

export function deleteFavorite(symbol) {
  return function (dispatch) {
    axiosInstance
      .post("/deleteFavouriteTicker", { symbol: symbol })
      .then((res) => {
        dispatch({
          type: TYPES.DELETE_TICKER_FROM_FAVORITE,
          payload: res.data
        });
      })
      .then(() => {
        toast.info(`${symbol} deleted from your watchlist`);
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getTickersByFields(fields) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_TICKERS_BY_FIELDS_IN_PROGRESS });
    axiosInstance
      .get(`tickers/?page_number=1&page_size=10`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKERS_BY_FIELDS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getTickersLivePrices() {
  return function (dispatch) {
    axiosInstance
      .get("/sentiments/tickers?fields=details,equidistant_nav,zag_price")
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKERS_LIVE_PRICES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}
