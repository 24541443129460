import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_TICKERS: "GET_TICKERS",
  GET_TICKERS_SENTIMENTS: "GET_TICKERS_SENTIMENTS",

  GET_TICKERS_ERROR: "GET_TICKERS_ERROR",

  GET_TICKER_BY_SYMBOL: "GET_TICKER_BY_SYMBOL",
  GET_TICKER_BY_SYMBOL_ERROR: "GET_TICKER_BY_SYMBOL_ERROR",

  GET_TICKERS_TO_COMPARE: "GET_TICKERS_TO_COMPARE",
  GET_TICKERS_TO_COMPARE_ERROR: "GET_TICKERS_TO_COMPARE_ERROR",

  ADD_NEW_TICKER: "ADD_NEW_TICKER",
  ADD_NEW_TICKER_ERROR: "ADD_NEW_TICKER_ERROR",

  EDIT_TICKER: "EDIT_TICKER",
  EDIT_TICKER_ERROR: "EDIT_TICKER_ERROR",

  DELETE_TICKER: "DELETE_TICKER",
  DELETE_TICKER_ERROR: "DELETE_TICKER_ERROR",

  GET_TICKER_BY_SYMBOL_IN_PROGRESS: "GET_TICKER_BY_SYMBOL_IN_PROGRESS",

  GET_TICKER_IN_PROGRESS: "GET_TICKER_IN_PROGRESS",
  TICKERS_ERROR: "TICKERS_ERROR"
};

export function getTickers() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_TICKER_IN_PROGRESS });
    axiosInstance
      .get(
        process.env.REACT_APP_API_HOST +
          process.env.REACT_APP_ROBO_ASSETSERVICE_TICKERS
      )
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKERS_SENTIMENTS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_TICKERS_SENTIMENTS,
          payload: err.message
        });
      });
  };
}

export function getLightTickers() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_TICKER_IN_PROGRESS });
    axiosInstance
      .get(`${process.env.REACT_APP_API_HOST}/tickers/light`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKERS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_TICKERS_ERROR,
          payload: err.message
        });
      });
  };
}

export const getTickerBySymbol = (symbol) => async (dispatch) => {
  dispatch({ type: TYPES.GET_TICKER_BY_SYMBOL_IN_PROGRESS });
  try {
    const res = await axiosInstance.get(`/tickers/${symbol}`);
    dispatch({
      type: TYPES.GET_TICKER_BY_SYMBOL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TYPES.GET_TICKER_BY_SYMBOL_ERROR,
      payload: err.message
    });
  }
};

export const getTickersToCompare = (symbol) => async (dispatch) => {
  dispatch({ type: TYPES.GET_TICKER_IN_PROGRESS });
  try {
    const res = await axiosInstance.get(`/tickers/${symbol}`);
    dispatch({
      type: TYPES.GET_TICKERS_TO_COMPARE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TYPES.GET_TICKERS_TO_COMPARE_ERROR,
      payload: err.message
    });
  }
};

/*
 ** Callback takes error message as first argument
 */
export function addTicker(values, callback) {
  return function (dispatch) {
    axiosInstance
      .post(process.env.REACT_APP_TICKERS, values)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_NEW_TICKER,
          payload: res.data
        });
        callback(null);
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || "Failed to add new ticker";
        dispatch({
          type: TYPES.ADD_NEW_TICKER_ERROR,
          payload: message
        });
        callback(message);
      });
  };
}

export function editTicker(editedTicker) {
  return function (dispatch) {
    axiosInstance
      .put(
        process.env.REACT_APP_API_HOST +
          process.env.REACT_APP_ROBO_ASSETSERVICE_TICKER,
        { editedTicker }
      )
      .then((res) => {
        dispatch({
          type: TYPES.GET_TICKER_BY_SYMBOL,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_TICKER_BY_SYMBOL_ERROR,
          payload: err.message
        });
      });
  };
}

export function deleteTicker(symbol, isRedirect = true) {
  return function (dispatch) {
    axiosInstance
      .delete(`/tickers?symbol=${symbol}`)
      .then((res) => {
        Swal.fire({
          title: "Deleted!",
          text: "Ticker deleted!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        }).then(() => {
          dispatch({
            type: TYPES.DELETE_TICKER,
            payload: symbol
          });
          if (isRedirect) {
            window.location.href = "/dashboard/security";
          }
        });
      })
      .catch((err) => {
        const errMsg =
          err?.response?.data?.message || `Failed to delete ${symbol}`;
        Swal.fire({
          title: "Delete failed",
          text: errMsg,
          type: "error"
        });
        dispatch({
          type: TYPES.DELETE_TICKER_ERROR,
          payload: errMsg
        });
      });
  };
}

export function updateTicker(symbol, values, callback) {
  return function (dispatch) {
    axiosInstance
      .put(`/tickers?symbol=${symbol}`, values)
      .then((res) => {
        dispatch({
          type: TYPES.EDIT_TICKER,
          payload: res.data
        });
        callback(null);
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || "Failed to add new ticker";
        dispatch({
          type: TYPES.EDIT_TICKER_ERROR,
          payload: message
        });
        callback(message);
      });
  };
}
