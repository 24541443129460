import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";

export interface BasketItem {
  id?: string;
  weight: number;
  ticker: any;
  maximum_bound_weight: number;
  minimum_bound_weight: number;
  [key: string]: any;
}

interface BasketItems {
  name: string;
  description: string;
  basket_items: BasketItem[any];
}

const initialState: BasketItems = {
  name: "",
  description: "",
  basket_items: [
    {
      weight: 1,
      ticker_id: 1,
      symbol: "CASH",
      name: "CASH",
      maximum_bound_weight: 1,
      minimum_bound_weight: 0
    }
  ]
};

const BasketItemsSlice = createSlice({
  name: "basketItems",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    addBasketItem: (state, action) => {
      const newItem = action.payload ?? {
        weight: 0,
        ticker_id: -1,
        maximum_bound_weight: 1,
        minimum_bound_weight: 0,
        // symbol: "",
        name: ""
      };

      state.basket_items.push(newItem);
    },
    updateBasketItem: (state, action) => {
      const { index, attribute } = action.payload;
      let newValue = action.payload.value;
      if (attribute === "weight") {
        let scaledCashWeight = state.basket_items[0].weight * 1000;
        scaledCashWeight += state.basket_items[index].weight * 1000 - Number(newValue) * 10;
        state.basket_items[0].weight = scaledCashWeight / 1000;
      }
      if (attribute.includes("weight")) newValue = +((newValue as number) / 100).toFixed(2);
      state.basket_items[index][attribute] = newValue;
    },
    updateBasketInfo: (state, action) => {
      const { index, ticker } = action.payload;
      state.basket_items[index] = {
        ...state.basket_items[index],
        ticker_id: ticker.id,
        name: ticker.name,
        symbol: ticker.symbol
      };
    },
    setBasketEdit: (state, action) => {
      state.basket_items = state.basket_items?.slice(0, 1)?.concat(action.payload);
      const scaledCashWeight =
        1000 - action.payload?.reduce((acc: any, curr: any) => acc + curr.weight * 1000, 0);
      state.basket_items[0].weight = +(scaledCashWeight / 1000).toFixed(2);
    },
    addMultipleBasketItem: (state, action) => {
      const items = action.payload.map((item: any) => ({
        name: item?.ticker?.name,
        weight: 0,
        minimum_bound_weight: 0,
        maximum_bound_weight: 1,
        symbol: item?.ticker?.symbol,
        ticker_id: item?.ticker?.id
      }));

      function isObjectInArray(array: any, object: any) {
        return array.some((item: any) => item?.symbol === object?.symbol);
      }
      // Function to push unique objects from the newObjectsArray to the uniqueObjects array
      function pushUniqueObjects(targetArray: any, sourceArray: any) {
        sourceArray.forEach((newObject: any) => {
          if (!isObjectInArray(targetArray, newObject)) {
            targetArray.push(newObject);
          }
        });
      }
      // Push the newObjectsArray into the uniqueObjects array while ensuring uniqueness
      pushUniqueObjects(state.basket_items, items);
    },
    removeMultipleBasketItem: (state, action) => {
      for (let i = 0; i < state.basket_items.length; i++) {
        const obj1 = state.basket_items[i];
        for (let j = 0; j < action.payload.length; j++) {
          const obj2 = action.payload[j];
          if (obj1.symbol === obj2.symbol) {
            state.basket_items.splice(i, 1); // Remove the duplicate object from the first array
            i--; // Decrement the index as the array is now one element shorter
            break; // Exit the inner loop since we found a match
          }
        }
      }
    },
    removeBasketItem: (state, action) => {
      const index = action.payload;
      if (index) {
        const scaledCashWeight: number =
          state.basket_items[0].weight * 1000 + +state.basket_items[index].weight * 1000;

        state.basket_items[0].weight = scaledCashWeight / 1000;
        state.basket_items.splice(index, 1);
      }
    }
  }
});
export const {
  setName,
  setDescription,
  addBasketItem,
  addMultipleBasketItem,
  updateBasketItem,
  updateBasketInfo,
  removeMultipleBasketItem,
  removeBasketItem,
  setBasketEdit
} = BasketItemsSlice.actions;

export const selectGlobalBasket = createDraftSafeSelector(
  (state: any) => state,
  (state) => state?.basketItems
);

export const selectBasket = createDraftSafeSelector(
  (state: any) => state,
  (state) => state?.basketItems?.basket_items
);

export const selectName = createDraftSafeSelector(
  (state: any) => state,
  (state) => state?.name
);

export const selectDescription = createDraftSafeSelector(
  (state: any) => state,
  (state) => state?.description
);

export const selectCashWeightCreate = createDraftSafeSelector(
  (state: any) => state,
  (state) => state.basketItems.basket_items[0].weight
);

export default BasketItemsSlice.reducer;
