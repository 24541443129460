import { TYPES } from './actions'

let initState = {
  commissions: [],
  history: [],
}

export function commissionReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_COMMISSIONS: {
      return {
        ...state,
        commissions: action.payload,
      }
    }

    case TYPES.ADD_COMMISSION: {
      state.commissions.push(action.payload)

      return state
    }

    case TYPES.UPDATE_COMMISSION: {
      const commissions = state.commissions.map(commission => {
        if (commission.type === action.payload.style) {
          commission.percentage = action.payload.percentage
        }

        return commission
      })
      return {
        ...state,
        commissions,
      }
    }

    case TYPES.GET_HISTORY: {
      return {
        ...state,
        history: action.payload,
      }
    }

    default:
      return state
  }
}
