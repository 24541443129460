import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";

export const dividendRebalancingApi = SplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDividendById: builder.query<any, any>({
      query: (id) => urlPrepare(process.env.REACT_APP_GET_DIVIDEND_BY_ID, { dividendId: id }),
      providesTags: ["DividendRebalancing"]
    }),
    rebalancingSimulation: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_REBALANCING_SIMULATION, { dividendId: id }),
        method: "PUT"
      }),
      invalidatesTags: ["DividendRebalancing"]
    }),
    rebalancingExecution: builder.mutation<any, any>({
      query: ({ id, investmentIds }) => ({
        url: urlPrepare(process.env.REACT_APP_REBALANCING_EXECUTION, { dividendId: id }),
        method: "PUT",
        body: { investmentIds }
      }),
      invalidatesTags: ["DividendRebalancing"]
    })
  })
});

export const {
  useGetDividendByIdQuery,
  useRebalancingSimulationMutation,
  useRebalancingExecutionMutation
} = dividendRebalancingApi;
