const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
const GET_TEMPLATE = "GET_TEMPLATE";
const ADD_TEMPLATE = "ADD_TEMPLATE";
const DELETE_TEMPLATE = "DELETE_TEMPLATE";
const EDIT_TEMPLATE = "EDIT_TEMPLATE";
const SUCCESS_NOTIF = "SUCCESS_NOTIF";
const DUP_KEY_ERROR = "DUP_KEY_ERROR";
const GENERAL_ERROR = "GENERAL_ERROR";
const RESET_NOTFICATION = "RESET_NOTFICATION";

module.exports = {
  GET_ALL_TEMPLATES,
  GET_TEMPLATE,
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  EDIT_TEMPLATE,
  SUCCESS_NOTIF,
  DUP_KEY_ERROR,
  GENERAL_ERROR,
  RESET_NOTFICATION,
};
