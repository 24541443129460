import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import { Role } from "./interfaces";

export const rolesApi = SplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserRoles: builder.query<any, void>({
            query: () => ({
                url: urlPrepare('/users/roles/all'),
            }),
            providesTags: ["UserRoles"]
        }),
        getPermissions: builder.query<any, void>({
            query: () => ({
                url: urlPrepare('/users/permissions/all')
            }),
            providesTags: ["Permissions"]
        }),
        getRoleDetails: builder.query<any, any>({
            query: (id) => ({
                url: urlPrepare(`/users/role/details/${id}`)
            })
        }),
        addRole: builder.mutation<any, Role>({
            query: (role) => ({
                url: urlPrepare(`${process.env.REACT_APP_ADD_ROLE}`),
                method: "POST",
                body: role
            }),
            invalidatesTags: ["UserRoles"],
        }),
        updateRole: builder.mutation<any, Role>({
            query: (role) => ({
                url: urlPrepare(`${process.env.REACT_APP_UPDATE_ROLE}`),
                method: "PUT",
                body: role
            }),
            invalidatesTags: ["UserRoles"],
        }),
        deleteRole: builder.mutation<any, number>({
            query: (roleId) => ({
                url: urlPrepare(`${process.env.REACT_APP_DELETE_ROLE}${roleId}`),
                method: "DELETE",
            }),
            invalidatesTags: ["UserRoles"],
        }),
    }),
})

export const {
    useGetUserRolesQuery,
    useGetPermissionsQuery,
    useGetRoleDetailsQuery,
    useAddRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation
} = rolesApi