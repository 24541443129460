import { TYPES } from "./actions";

let initState = {
  authenticatedUser: {},
};

export function authReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.LOGIN_SUCCESS: {
      return {
        ...state,
        authenticatedUser: action.payload
      };
    }
    case TYPES.GET_ME: {
      return {
        ...state,
        authenticatedUser: action.payload
      };
    }
    case TYPES.LOGOUT: {
      return {
        ...state,
        authenticatedUser: {}
      };
    }
    default:
      return state;
  }
}