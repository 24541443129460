import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";



export const PamApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getManagers: builder.query<any, any>({
      query: ({ page_number, page_size, managers_only }) => ({
        url: urlPrepare(`/managers?page_number=${page_number}&page_size=${page_size}&managers_only=true`),
        method: "GET",
        headers: {
          "authorization": `Bearer ${localStorage.getItem("token")}`,
          "X-Users-Count": "true"
        }
      }),

      providesTags: ["ProfessionalAssetManagers"]
    }),
    getManagerById: builder.query<any, any>({
      query: (id) => urlPrepare(`/managers/${id}`),
      providesTags: ["ProfessionalAssetManagers"]
    }),
    createManager: builder.mutation<any, any>({
      query: (body) => ({
        url: urlPrepare(`/managers`),
        method: "POST",
        body
      }),
      invalidatesTags: ["ProfessionalAssetManagers"]
    }),
    updateManager: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/managers/${id}`),
        method: "PUT",
        body
      }),
      invalidatesTags: ["ProfessionalAssetManagers"]
    }),
    deleteManager: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(`/managers/${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["ProfessionalAssetManagers"]
    })
  })
});

export const {
    useCreateManagerMutation,
    useDeleteManagerMutation,
    useGetManagerByIdQuery,
    useGetManagersQuery,
    useUpdateManagerMutation
} = PamApi;