import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const groupsApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<any, any>({
      query: ({ page_number, page_size, role, owner_id, manager_id }) => {
        const parameter = (role !== 'ADMIN' && owner_id) ? `owner_id=${owner_id}` : (role !== 'ADMIN' && manager_id) ? `manager_id=${manager_id}` : '';
        return urlPrepare(`/managers/groups?page_number=${page_number}&page_size=${page_size}&${parameter}`);
      },
      providesTags: ["Groups"]
    }),
    getAllGroups: builder.query<any, any>({
      query: ({ page_number, page_size }) =>
        urlPrepare(`/managers/groups?page_number=${page_number}&page_size=${page_size}`),
      providesTags: ["Groups"]
    }),
    getGroupById: builder.query<any, any>({
      query: (id) => urlPrepare(`/managers/groups/${id}`),
      providesTags: ["Groups"]
    }),
    createGroup: builder.mutation<any, any>({
      query: (body) => ({
        url: urlPrepare(`/managers/groups`),
        method: "POST",
        body
      }),
      invalidatesTags: ["Groups"]
    }),
    updateGroup: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/managers/groups/${id}`),
        method: "PUT",
        body
      }),
      invalidatesTags: ["Groups"]
    }),
    deleteGroup: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(`/managers/groups/${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["Groups"]
    }),
    getUsersInGroup: builder.query<any, any>({
      query: ({ group_id, page_number, page_size }) =>
        urlPrepare(
          `/managers/groups/${group_id}/members?page_number=${page_number}&page_size=${page_size}`
        ),
      providesTags: ["Groups"]
    }),
    addUserToGroup: builder.mutation<any, any>({
      query: ({ group_id, user_id }) => ({
        url: urlPrepare(`/managers/groups/${group_id}/members/${user_id}`),
        method: "POST"
      }),
      invalidatesTags: ["Groups"]
    }),
    removeUserFromGroup: builder.mutation<any, any>({
      query: ({ group_id, user_id }) => ({
        url: urlPrepare(`/managers/groups/${group_id}/members/${user_id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["Groups"]
    }),
    getClients: builder.query<any, any>({
      query: ({ page_number, page_size, none_group_member }) =>
        none_group_member
          ? urlPrepare(
            `/users?page_number=${page_number}&page_size=${page_size}&none_group_member=${none_group_member}`
          )
          : urlPrepare(`/users?page_number=${page_number}&page_size=${page_size}`)
    }),
    getClientFullInfo: builder.query<any, any>({
      query: (id) => urlPrepare(`/users/${id}/full-info`),
      providesTags: ["Groups"]
    })
  })
});

export const {
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetGroupByIdQuery,
  useGetGroupsQuery,
  useGetUsersInGroupQuery,
  useUpdateGroupMutation,
  useAddUserToGroupMutation,
  useRemoveUserFromGroupMutation,
  useGetClientsQuery,
  useGetAllGroupsQuery,
  useGetClientFullInfoQuery
} = groupsApi;
