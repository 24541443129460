import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
  GET_ALL_MODELS: 'GET_ALL_MODELS',
  GET_ALL_MODELS_ERROR: 'GET_ALL_MODELS_ERROR',

  GET_ALL_FREQUENCIES: 'GET_ALL_FREQUENCIES',
  GET_ALL_FREQUENCIES_IN_PROGRESS: "GET_ALL_FREQUENCIES_IN_PROGRESS",
  GET_ALL_FREQUENCIES_ERROR: 'GET_ALL_FREQUENCIES_ERROR',

  GET_ALL_BENCHMARKS: 'GET_ALL_BENCHMARKS',
  GET_ALL_BENCHMARKS_IN_PROGRESS: 'GET_ALL_BENCHMARKS_IN_PROGRESS',
  GET_ALL_BENCHMARKS_ERROR: 'GET_ALL_BENCHMARKS_ERROR',

};

export function getAllFrequencies() {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ALL_FREQUENCIES_IN_PROGRESS,
    });
    axiosInstance.get(process.env.REACT_APP_GET_ALL_FREQUENCIES)
      .then(res => {
        dispatch({
          type: TYPES.GET_ALL_FREQUENCIES,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: TYPES.GET_ALL_FREQUENCIES_ERROR,
          payload: err.message
        });
      });
  };
};

export function getAllBenchmarks() {
  return function (dispatch) {
    dispatch({
      type: TYPES.GET_ALL_BENCHMARKS_IN_PROGRESS,
    });
    axiosInstance.get(process.env.REACT_APP_GET_ALL_BENCHMARKS)
      .then(res => {
        dispatch({
          type: TYPES.GET_ALL_BENCHMARKS,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: TYPES.GET_ALL_FREQUENCIES_ERROR,
          payload: err.message
        });
      });
  };
};

export function getAllModels() {
    return function (dispatch) {
      axiosInstance.get(process.env.REACT_APP_GET_ALL_MODELS)
        .then(res => {
          dispatch({
            type: TYPES.GET_ALL_MODELS,
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({
            type: TYPES.GET_ALL_MODELS_ERROR,
            payload: err.message
          });
        });
    };
  };