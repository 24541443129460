import { TYPES } from "./actions";

let initState = {
    sentiments: [],
};

export function sentimentReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_SENTIMENTS: {
      return {
        ...state,
        sentiments: action.payload
      };
    }
    case TYPES.SENTIMENTS_ERROR: {
      return {
        ...state,
        sentiments: action.payload
      };
    }
    default:
      return state;
  }
}