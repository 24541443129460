import { TYPES } from "./actions";

let initState = {
  client: null,
  clients: [],
  managerClients: [],
  activeClients: [],
  onboardingClients: [],
  coldClients: [],
  rejectedAndDeletedClients: [],
  balance: 0,
  commentError: undefined,
  comments: undefined,
  errors: undefined,
  isLoading: false,
  getManagedClientsIsLoading: false,
  getManagedOnboardingClientsIsLoading: false,

  managerOnboardingClients: [],
  getClientsIsLoading: false
};

export function clientReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_ACTIVE_CLIENTS_BY_MANAGER_ID: {
      return {
        ...state,
        managerClients: action.payload,
        getManagedClientsIsLoading: false
      };
    }
    case TYPES.GET_ACTIVE_CLIENTS_BY_MANAGER_ID_IN_PROGRESS: {
      return {
        ...state,
        getManagedClientsIsLoading: true
      };
    }
    case TYPES.GET_ACTIVE_CLIENTS_BY_MANAGER_ID_ERROR: {
      return {
        ...state,
        getManagedClientsIsLoading: false
      };
    }

    case TYPES.GET_ONBOARDING_CLIENTS_BY_MANAGER_ID: {
      return {
        ...state,
        managerOnboardingClients: action.payload,
        getManagedOnboardingClientsIsLoading: false
      };
    }
    case TYPES.GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_IN_PROGRESS: {
      return {
        ...state,
        getManagedOnboardingClientsIsLoading: true
      };
    }
    case TYPES.GET_ONBOARDING_CLIENTS_BY_MANAGER_ID_ERROR: {
      return {
        ...state,
        getManagedOnboardingClientsIsLoading: false
      };
    }

    case TYPES.GET_CLIENTS: {
      return {
        ...state,
        clients: action.payload,
        getClientsIsLoading: false
      };
    }
    case TYPES.GET_CLIENTS_IN_PROGRESS: {
      return {
        ...state,
        getClientsIsLoading: true,
        isLoading: true
      };
    }

    case TYPES.UPDATE_CLIENT_MANAGER_ID: {
      return {
        ...state,
        managerClients: action.payload
      };
    }

    case TYPES.GET_ACTIVE_CLIENTS: {
      return {
        ...state,
        activeClients: action.payload
      };
    }
    case TYPES.GET_ONBOARDING_CLIENTS: {
      return {
        ...state,
        onboardingClients: action.payload
      };
    }
    case TYPES.GET_REJECTED_AND_DELETED_CLIENTS: {
      return {
        ...state,
        rejectedAndDeletedClients: action.payload
      };
    }

    case TYPES.GET_ACTIVE_CLIENTS_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TYPES.GET_ONBOARDING_CLIENTS_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TYPES.GET_REJECTED_AND_DELETED_CLIENTS_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    //--------------------------
    case TYPES.CLIENT_APPROVE:
    case TYPES.CLIENT_INFO: {
      return {
        ...state,
        client: action.payload
      };
    }

    case TYPES.GET_CLIENT_BALANCE: {
      return {
        ...state,
        balance: action.payload
      };
    }

    case TYPES.COMMENT_ERROR: {
      return {
        ...state,
        commentError: action.payload
      };
    }
    case TYPES.COMMENTS: {
      return {
        ...state,
        client: {
          ...state.client,
          adminOnboardingForm: action.payload
        },
        comments: action?.payload?.comments
      };
    }
    case TYPES.CLIENTS_ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case TYPES.CLIENT_ONBOARDING_FORM: {
      return {
        ...state,
        client: {
          ...state.client,
          adminOnboardingForm: action.payload
        }
      };
    }
    case TYPES.VALIDATE_CLIENT_FILES: {
      return {
        ...state,
        client: action.payload
      };
    }
    case TYPES.VALIDATE_CLIENT_FILES_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case TYPES.GET_COLD_CLIENTS: {
      return {
        ...state,
        coldClients: action.payload
      };
    }
    case TYPES.TOGGLE_CLIENT_COLD: {
      return {
        ...state,
        client: {
          ...state.client,
          isCold: action.payload
        }
      };
    }
    default:
      return state;
  }
}
