import axiosInstance from "helpers/axiosInstance";

import Swal from "sweetalert2";

export const TYPES = {
  GET_ALL_RISK_PROFILE: "GET_ALL_RISK_PROFILE",
  GET_ALL_RISK_PROFILE_ERROR: "GET_ALL_RISK_PROFILE_ERROR",

  GET_RISK_PROFILE_BY_ID: "GET_RISK_PROFILE_BY_ID",
  GET_RISK_PROFILE_BY_ID_ERROR: "GET_RISK_PROFILE_BY_ID_ERROR",

  ADD_RISK_PROFILE: "ADD_RISK_PROFILE",
  ADD_RISK_PROFILE_ERROR: "ADD_RISK_PROFILE_ERROR",

  GET_RISK_PROFILE_IN_PROGRESS: "GET_RISK_PROFILE_IN_PROGRESS",
  ADD_RISK_PROFILE_IN_PROGRESS: "ADD_RISK_PROFILE_IN_PROGRESS"
};

export function getAllRiskProfile() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_RISK_PROFILE_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_GET_ALL_RISK_PROFILE)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_RISK_PROFILE,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_RISK_PROFILE_ERROR,
          payload: err.message
        });
      });
  };
}

export function getRiskProfileById(risk_profil_id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_RISK_PROFILE_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_GET_RISK_PROFILE_BY_ID + risk_profil_id)
      .then((res) => {
        dispatch({
          type: TYPES.GET_RISK_PROFILE_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_RISK_PROFILE_BY_ID_ERROR,
          payload: err.message
        });
      });
  };
}

export function addNewRiskProfile(newRiskProfile) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_RISK_PROFILE_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_ADD_RISK_PROFILE, newRiskProfile)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_RISK_PROFILE,
          payload: res.data
        });
        Swal.fire({
          icon: "success",
          title: "New Risk Profile Matrix added successfully",
          confirmButtonText: "Done"
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_RISK_PROFILE_ERROR,
          payload: err.message
        });
        Swal.fire({
          type: "error",
          icon: "error",
          title: "Add risk Profile matrix failed",
          text: "Failed to add your data"
        });
      });
  };
}
