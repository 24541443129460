import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  tickers: any[];
}

const initialState: InitialState = {
  tickers: []
};
const tickersSlice = createSlice({
  name: "tickers",
  initialState,
  reducers: {
    addTicker: (state, action) => {
      state.tickers.push(action.payload);
    },
    removeTicker: (state, action) => {
      const index = state.tickers.findIndex((ticker) => ticker.symbol === action.payload);
      if (index !== -1) {
        state.tickers.splice(index, 2);
      }
    }
  }
});
export const { addTicker, removeTicker } = tickersSlice.actions;
export default tickersSlice.reducer;
