import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const SolutionApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getSolutions: builder.query<any, any>({
      query: ({ page_number, page_size }) =>
        urlPrepare(`/solutions?page_number=${page_number}&page_size=${page_size}`),
      providesTags: ["Solutions"]
    }),
    getSolution: builder.query<any, any>({
      query: (solution_id) => urlPrepare(`/solutions/${solution_id}`),
      providesTags: ["Solutions"]
    }),
    deleteSolution: builder.mutation<any, any>({
        query: ({ solution_id }) => ({
            url: `/solutions/${solution_id}`,
            method: "DELETE"
        }),
        invalidatesTags: ["Solutions"]
    }),
    createSolution: builder.mutation<any, any>({
      query: ({ solution }) => ({
        url: `/solutions`,
        method: "POST",
        body: solution
      }),
      invalidatesTags: ["Solutions"]
    }),
    updateSolution: builder.mutation<any, any>({
      query: ({ solution_id, solution }) => ({
        url: `/solutions/${solution_id}`,
        method: "PUT",
        body: solution
      }),
      invalidatesTags: ["Solutions"]
    }),
    getModels: builder.query<any, any>({
      query: ({ page_number, page_size }) =>
        urlPrepare(`/solutions/models?page_number=${page_number}&page_size=${page_size}`),
      providesTags: ["Solutions"]
    }),
    createRiskProfileMatrix: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: `/risk-profile-matrices`,
        method: "POST",
        body: body
      }),
      invalidatesTags: ["RiskProfileMatrix"]
    }),
    getMatrixById: builder.query<any, any>({
      query: (matrix_id) => urlPrepare(`/risk-profile-matrices/${matrix_id}`),
      providesTags: ["RiskProfileMatrix"]
    }),
    getAllMatrixes: builder.query<any, any>({
      query: ({ page_number, page_size }) =>
        urlPrepare(`/risk-profile-matrices?page_number=${page_number}&page_size=${page_size}`),
      providesTags: ["RiskProfileMatrix"]
    }),
    updateMatrix: builder.mutation<any, any>({
      query: ({ matrix_id, body }) => ({
        url: `/risk-profile-matrices/${matrix_id}`,
        method: "PUT",
        body: body
      }),
      invalidatesTags: ["RiskProfileMatrix"]
    }),
    deleteMatrix: builder.mutation<any, any>({
      query: ({ matrix_id }) => ({
        url: `/risk-profile-matrices/${matrix_id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["RiskProfileMatrix"],
    }),
  })
});

export const {
    useGetSolutionsQuery,
    useGetSolutionQuery,
    useCreateSolutionMutation,
    useGetModelsQuery,
    useDeleteSolutionMutation,
    useCreateRiskProfileMatrixMutation,
    useGetMatrixByIdQuery,
    useGetAllMatrixesQuery,
    useUpdateMatrixMutation,
    useDeleteMatrixMutation,
    useUpdateSolutionMutation
} = SolutionApi;