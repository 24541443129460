import axiosInstance from '../../helpers/axiosInstance'

export const TYPES = {
  ADD_COMMISSION: 'ADD_COMMISSION',
  GET_COMMISSIONS: 'GET_COMMISSIONS',
  UPDATE_COMMISSION: 'UPDATE_COMMISSION',
  GET_HISTORY: 'GET_HISTORY',
}

export function addCommission(commission) {
  return async dispatch => {
    try {
      await axiosInstance.post(`${process.env.REACT_APP_COMMISSION_ROUTE}`, {
        type: commission.style,
        percentage: commission.commissionPercent,
      })

      dispatch({
        type: TYPES.ADD_COMMISSION,
        payload: {
          type: commission.style,
          percentage: commission.commissionPercent,
        },
      })
    } catch (error) {
      throw Error("can't add the new commission")
    }
  }
}

export function getCommissions() {
  return async dispatch => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_COMMISSION_ROUTE}`,
      )

      dispatch({
        type: TYPES.GET_COMMISSIONS,
        payload: data,
      })
    } catch (error) {
      throw Error("can't get commissions")
    }
  }
}

export function updateCommission(style, percentage) {
  return async dispatch => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_COMMISSION_ROUTE}/${style}`,
        {
          percentage,
        },
      )

      dispatch({
        type: TYPES.UPDATE_COMMISSION,
        payload: {
          style,
          percentage,
        },
      })
    } catch (error) {
      throw Error("can't update commission")
    }
  }
}

export function getHistory(style) {
  return async dispatch => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_COMMISSION_ROUTE}/history/${style}`,
      )

      dispatch({
        type: TYPES.GET_HISTORY,
        payload: data,
      })
    } catch (error) {
      throw Error("can't  get history")
    }
  }
}
