import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
  GET_NEWS_ARTICLES: "GET_NEWS_ARTICLES",
  GET_NEWS_ARTICLES_IN_PROGRESS: "GET_NEWS_ARTICLES_IN_PROGRESS",
  GET_NEWS_ARTICLES_ERROR: "GET_NEWS_ARTICLES_ERROR"
};

export function getNews(numberOfArficles, lookBack) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_NEWS_ARTICLES_IN_PROGRESS });
    axiosInstance
      .get(
        `${process.env.REACT_APP_GET_NEWS_ARTICLES}?number_of_articles=${numberOfArficles}&lookback=${lookBack}`
      )
      .then((res) => {
        dispatch({
          type: TYPES.GET_NEWS_ARTICLES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_NEWS_ARTICLES_ERROR,
          payload: err
        });
      });
  };
}
