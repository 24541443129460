import axiosInstance, { axiosInstanceV2 } from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import dayjs from "dayjs";

export const TYPES = {
  ROBOSERVER_GET_ORDERS: "ROBOSERVER_GET_ORDERS",
  ROBOSERVER_GET_FAILED_ORDERS: "ROBOSERVER_GET_FAILED_ORDERS",
  ROBOSERVER_GET_ALL_ORDERS: "ROBOSERVER_GET_ALL_ORDERS",
  ROBOSERVER_ORDERS_IS_LOADING: "ROBOSERVER_ORDERS_IS_LOADING",
  ROBOSERVER_ORDERS_ERROR: "ROBOSERVER_ORDERS_ERROR"
};

export function getInvestmentOrders(investmentId) {
  return function (dispatch) {
    return axiosInstance
      .get(
        urlPrepare(process.env.REACT_APP_GET_INVESTMENT_ZAG_ORDERS, {
          investmentId
        })
      )
      .then((res) => {
        dispatch({
          type: TYPES.ROBOSERVER_GET_ORDERS,
          payload: res.data
        });
      });
  };
}
export function getAllOrders({ date }) {
  return function (dispatch) {
    dispatch({
      type: TYPES.ROBOSERVER_ORDERS_IS_LOADING,
      payload: true
    });
    dispatch({
      type: TYPES.ROBOSERVER_ORDERS_ERROR,
      payload: undefined
    });

    const targetDate = date ?? dayjs().format("YYYY-MM-DD");

    return axiosInstanceV2
      .get(process.env.REACT_APP_GET_ROBOSERVER_ORDERS, {
        params: {
          page_size: Number.MAX_SAFE_INTEGER,
          page_number: 1,
          from_date: targetDate,
          to_date: targetDate,
          "X-Fields": [
            "id",
            "account_id",
            "investment_id",
            "ticker",
            "user_id",
            "fill_quantity",
            "remain_quantity",
            "fill_value",
            "source",
            "status",
            "fill_price",
            "order_side",
            "order_type",
            "ticker_id",
            "broker_message"
          ]
        }
      })
      .then((res) => {
        dispatch({
          type: TYPES.ROBOSERVER_GET_ORDERS,
          payload: res.data
        });
        dispatch({
          type: TYPES.ROBOSERVER_ORDERS_IS_LOADING,
          payload: false
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ROBOSERVER_ORDERS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getInvestmentFailedOrders(investmentId) {
  return function (dispatch) {
    return axiosInstance
      .get(
        urlPrepare(process.env.REACT_APP_GET_INVESTMENT_ZAG_FAILED_ORDERS, {
          investmentId
        })
      )
      .then((res) => {
        dispatch({
          type: TYPES.ROBOSERVER_GET_FAILED_ORDERS,
          payload: res.data
        });
      });
  };
}
