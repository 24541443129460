import { urlPrepare } from "helpers";
import {  SplitApiV2 } from "redux/apiv2";

export const tickersApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getTickers: builder.query<any, any>({
      query: ({ pageNumber, pageSize, symbol, asset_class, sector, region }) => {
        let queryParams = `/tickers/?page_number=${pageNumber}&page_size=${pageSize}&sort_order=asc`;
        if (symbol) {
          queryParams += `&symbol=${symbol}`;
        } else if (asset_class) {
          queryParams += `&asset_class=${asset_class}`;
        } else if (sector) {
          queryParams += `&sector=${sector}`;
        } else if (region) {
          queryParams += `&region=${region}`;
        }
        return urlPrepare(queryParams);
      },
      providesTags: ["Tickers"]
    }),
    getTickerById: builder.query<any, any>({
      query: (id) => urlPrepare(`/tickers/${id}`),
      providesTags: ["Tickers"]
    }),
    getTickerBySymbol: builder.query<any, any>({
      query: (symbol) => urlPrepare(`/tickers/symbol/${symbol}`),
      providesTags: ["Tickers"]
    }),
    createWatchlist: builder.mutation<any, any>({
      query: (body) => ({
        url: urlPrepare(`/tickers/watchlists`),
        method: "POST",
        body
      }),
      invalidatesTags: ["Watchlists"]
    }),
    getWatchlists: builder.query<any, any>({
      query: ({ pageNumber, pageSize }) =>
        urlPrepare(`/tickers/watchlists?page_number=${pageNumber}&page_size=${pageSize}`),
      providesTags: ["Watchlists"]
    }),
    getWatchlistById: builder.query<any, any>({
      query: (id) => urlPrepare(`/tickers/watchlists/${id}`),
      providesTags: ["Watchlists"]
    }),
    updateWatchlist: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/tickers/watchlists/${id}`),
        method: "PUT",
        body
      }),
      invalidatesTags: ["Watchlists"]
    }),
    deleteWatchlist: builder.mutation<any, any>({
      query: (id) => ({
        url: urlPrepare(`/tickers/watchlists/${id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["Watchlists"]
    }),
    addTickerToWatchList: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: urlPrepare(`/tickers/watchlists/${id}/ticker`),
        method: "POST",
        body
      }),
      invalidatesTags: ["Watchlists"]
    }),
    removeTickerFromWatchlist: builder.mutation<any, any>({
      query: ({ watchlist_id, ticker_id }) => ({
        url: urlPrepare(`/tickers/watchlists/${watchlist_id}/ticker/${ticker_id}`),
        method: "DELETE"
      }),
      invalidatesTags: ["Watchlists"]
    }),
    getAllBenchMarks: builder.query<any, any>({
      query: ({ pageNumber, pageSize }) => urlPrepare(`/tickers/benchmarks/?page_number=${pageNumber}&page_size=${pageSize}`),
      providesTags: ["Tickers"]
    })
  })
});

export const {
  useGetTickersQuery,
  useGetTickerByIdQuery,
  useGetTickerBySymbolQuery,
  useCreateWatchlistMutation,
  useGetWatchlistByIdQuery,
  useGetWatchlistsQuery,
  useDeleteWatchlistMutation,
  useAddTickerToWatchListMutation,
  useRemoveTickerFromWatchlistMutation,
  useUpdateWatchlistMutation,
  useGetAllBenchMarksQuery
} = tickersApi;
