import { toast } from "react-toastify";
import Swal from "sweetalert2";

import axiosInstance from "../../helpers/axiosInstance";

export const TYPES = {
  GET_ALL_STRATEGIES: "GET_ALL_STRATEGIES",
  GET_ALL_STRATEGIES_ERROR: "GET_ALL_STRATEGIES_ERROR",
  GET_STRATEGIES_IN_PROGRESS: "GET_STRATEGIES_IN_PROGRESS",

  GET_ALL_STRATEGY_GROUPS: "GET_ALL_STRATEGY_GROUPS",
  GET_ALL_STRATEGY_GROUPS_ERROR: "GET_ALL_STRATEGY_GROUPS_ERROR",

  GET_ALL_STRATEGY_GROUPS_BY_ID: "GET_ALL_STRATEGY_GROUPS_BY_ID",
  GET_ALL_STRATEGY_GROUPS_BY_ID_ERROR: "GET_ALL_STRATEGY_GROUPS_BY_ID_ERROR",

  GET_ALL_STRATEGY_GROUPS_BY_MANAGER_ID:
    "GET_ALL_STRATEGY_GROUPS_MANAGER_BY_ID",
  GET_ALL_STRATEGY_GROUPS_BY_MANAGER_ID_ERROR:
    "GET_ALL_STRATEGY_GROUPS_BY_MANAGER_ID_ERROR",

  GET_STRATEGY_BY_ID: "GET_STRATEGY_BY_ID",
  GET_STRATEGY_BY_ID_IN_PROGRESS: "GET_STRATEGY_BY_ID_IN_PROGRESS",
  GET_STRATEGIE_BY_ID_ERROR: "GET_STRATEGIE_BY_ID_ERROR",

  GET_STRATEGY_PERFORMANCE: "GET_STRATEGY_PERFORMANCE",
  GET_STRATEGY_PERFORMANCE_IN_PROGRESS: "GET_STRATEGY_PERFORMANCE_IN_PROGRESS",
  GET_STRATEGY_PERFORMANCE_ERROR: "GET_STRATEGY_PERFORMANCE_ERROR",

  ADD_NEW_STRATEGY: "ADD_NEW_STRATEGY",
  ADD_NEW_STRATEGY_IN_PROGRESS: "ADD_NEW_STRATEGY_IN_PROGRESS",
  ADD_NEW_STRATEGY_ERROR: "ADD_NEW_STRATEGY_ERROR",

  EDIT_STRATEGY: "EDIT_STRATEGY",
  EDIT_STRATEGY_IN_PROGRESS: "EDIT_STRATEGY_IN_PROGRESS",
  EDIT_STRATEGIE_ERROR: "EDIT_STRATEGIE_ERROR",

  DELETE_STRATEGIES: "DELETE_STRATEGIES",
  DELETE_STRATEGIE_ERROR: "DELETE_STRATEGIE_ERROR"
};

export function getStrategies() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_STRATEGIES_IN_PROGRESS });
    axiosInstance
      .get(`${process.env.REACT_APP_GET_STRATEGIES}`, {
        headers: {
          "x-fields":
            "_id,smartfolio_name,asset_class,market,portal_name,name,description,solution_body,basket_body,notional,creation_date,frequency"
        }
      })
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGIES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGIES_ERROR,
          payload: err.message
        });
      });
  };
}

export function getStrategyPerformance(strategyId) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_STRATEGY_PERFORMANCE_IN_PROGRESS });
    axiosInstance
      .get(`/admin/navs/history?smartfolioID=${strategyId}&clientType=`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_STRATEGY_PERFORMANCE,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_STRATEGY_PERFORMANCE_ERROR,
          payload: err.message
        });
      });
  };
}

export function getStrategyGroups() {
  return function (dispatch) {
    axiosInstance
      .get("/pam/strategy-group/")
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGY_GROUPS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGY_GROUPS_ERROR,
          payload: err.message
        });
      });
  };
}

export function getStrategyGroupsById(strategY_group_id) {
  return function (dispatch) {
    axiosInstance
      .get("/pam/strategy-group/" + { strategY_group_id })
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGY_GROUPS_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGY_GROUPS_BY_ID_ERROR,
          payload: err.message
        });
      });
  };
}

export function getStrategyGroupsByManagerId(strategy_manager_id) {
  return function (dispatch) {
    axiosInstance
      .get(`/pam/strategy-group-by-manager/?managerId=${strategy_manager_id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGY_GROUPS_BY_MANAGER_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_ALL_STRATEGY_GROUPS_BY_MANAGER_ID_ERROR,
          payload: err.message
        });
      });
  };
}

export function getStrategyById(strategie_id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_STRATEGY_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(
        process.env.REACT_APP_GET_STRATEGIE_BY_ID +
          { strategie_id }.strategie_id
      )
      .then((res) => {
        dispatch({
          type: TYPES.GET_STRATEGY_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_STRATEGIE_BY_ID_ERROR,
          payload: err.message
        });
      });
  };
}

export function newStrategy(newStrategy) {
  return function (dispatch) {
    dispatch({ type: TYPES.ADD_NEW_STRATEGY_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_ADD_NEW_STRATEGIE, newStrategy)
      .then((response) => {
        dispatch({
          type: TYPES.ADD_NEW_STRATEGY,
          payload: response
        });
        Swal.fire({
          icon: "success",
          title: "Strategy created successfully",
          confirmButtonText: "Done"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/strategy/");
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ADD_NEW_STRATEGY,
          payload: err.response
        });
        toast.error("Failed to create Strategy");
        if (err.response) {
          throw err.response;
        }
      });
  };
}

export function editStrategy(editedStrategie, id) {
  return function (dispatch) {
    dispatch({ type: TYPES.EDIT_STRATEGY_IN_PROGRESS });

    axiosInstance
      .put(process.env.REACT_APP_ADD_NEW_STRATEGIE + id, editedStrategie)
      .then((res) => {
        dispatch({
          type: TYPES.EDIT_STRATEGY,
          payload: res
        });
        Swal.fire({
          icon: "success",
          title: "Strategy updated successfully",
          confirmButtonText: "Done"
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.EDIT_STRATEGIE_ERROR,
          payload: err.message
        });
        toast.error("Failed To edit Strategy");
        if (err.response) {
          throw err.response;
        }
      });
  };
}

export function deleteStrategy(id) {
  return function (dispatch) {
    axiosInstance
      .delete(process.env.REACT_APP_DELETE_STRATEGIE_BY_ID + id)
      .then(() => {
        Swal.fire({
          title: "Deleted!",
          text: "Strategy deleted!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        }).then(() => {
          dispatch({
            type: TYPES.DELETE_STRATEGIES,
            payload: [id]
          });
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.DELETE_STRATEGIE_ERROR,
          payload: message || "Failed to delete strategy"
        });
        Swal.fire({
          title: "Delete failed",
          text:
            typeof message === "string" ? message : "Failed to delete Strategy",
          type: "error"
        });
      });
  };
}

export const deleteMultipleStrategies = async (items) => {
  try {
    const response = await axiosInstance.delete(
      `/settings/strategies?items=${items}`
    );
    return response.data;
  } catch (error) {
    return error?.response.data || { error: "Failed to delete strategies" };
  }
};
