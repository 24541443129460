import axiosInstance from "../../helpers/axiosInstance";
import Swal from "sweetalert2";

export const TYPES = {
  GET_ALL_BACKTESTS: "GET_ALL_BACKTESTS",
  GET_ALL_BACKTESTS_IN_PROGRESS: "GET_ALL_BACKTESTS_IN_PROGRESS",
  GET_ALL_BACKTESTS_ERROR: "GET_ALL_BACKTESTS_ERROR",

  GET_BACKTEST_BY_ID: "GET_BACKTEST_BY_ID",
  GET_BACKTEST_BY_ID_IN_PROGRESS: "GET_BACKTEST_BY_ID_IN_PROGRESS",
  GET_BACKTEST_BY_ID_ERROR: "GET_BACKTEST_BY_ID_ERROR",

  CREATE_BACKTEST: "CREATE_BACKTEST",
  CREATE_BACKTEST_IN_PROGRESS: "CREATE_BACKTEST_IN_PROGRESS",
  CREATE_BACKTEST_ERROR: "CREATE_BACKTEST_ERROR",

  DELETE_BACKTEST: "DELETE_BACKTEST",
  DELETE_BACKTEST_IN_PROGRESS: "DELETE_BACKTEST_IN_PROGRESS",
  DELETE_BACKTEST_ERROR: "DELETE_BACKTEST_ERROR"
};

export function getAllBackTests() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_ALL_BACKTESTS_IN_PROGRESS });
    axiosInstance
      .get(`${process.env.REACT_APP_BACKTEST}all`, {
        headers: {
          "x-fields": "_id,details,status"
        }
      })
      .then((res) => {
        dispatch({
          type: TYPES.GET_ALL_BACKTESTS,
          payload: res?.data
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch({
          type: TYPES.GET_ALL_BACKTESTS_ERROR,
          payload: err?.response?.data || { message: "Failed to get BackTests" }
        });
      });
  };
}

export function getBackTestById(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_BACKTEST_BY_ID_IN_PROGRESS });
    axiosInstance
      .get(`/solver/backtests/${id}`)
      .then((res) => {
        dispatch({
          type: TYPES.GET_BACKTEST_BY_ID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_BACKTEST_BY_ID_ERROR,
          payload: err?.response.data || { message: "Failed to get BackTest" }
        });
      });
  };
}

export function createBackTest(backTest) {
  return function (dispatch) {
    dispatch({ type: TYPES.CREATE_BACKTEST_IN_PROGRESS });
    axiosInstance
      .post(process.env.REACT_APP_BACKTEST, backTest)
      .then((response) => {
        dispatch({
          type: TYPES.CREATE_BACKTEST,
          payload: response
        });
        Swal.fire({
          icon: "success",
          title: "Your work has been saved",
          confirmButtonText: "Ok, got it!"
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace("/smartfolios/backtest/");
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.CREATE_BACKTEST_ERROR,
          payload: err?.response?.data || {
            message: "Failed to add new BackTest"
          }
        });
        Swal.fire({
          title: "Failed",
          text: "Failed To Create a New Backtest",
          icon: "error",
          type: "error"
        });
      });
  };
}

export function deleteBackTest(id) {
  return function (dispatch) {
    dispatch({ type: TYPES.DELETE_BACKTEST_IN_PROGRESS });
    axiosInstance
      .delete(process.env.REACT_APP_BACKTEST + id)
      .then(() => {
        Swal.fire({
          title: "Deleted!",
          text: "BackTest deleted !",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        }).then(() => {
          dispatch({
            type: TYPES.DELETE_BACKTEST,
            payload: [id]
          });
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        dispatch({
          type: TYPES.DELETE_BACKTEST_ERROR,
          payload: message || "Failed to delete BackTest"
        });
        Swal.fire({
          title: "Delete failed",
          text:
            typeof message === "string" ? message : "Failed to delete BackTest",
          type: "error"
        });
      });
  };
}
