import { TYPES } from "./actions";
const initState = {
  list: {},
  isLoading: true,
  errors: undefined
};

export function ordersSummaryReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_ORDERS_SUMMARY: {
      return {
        ...state,
        list: action.payload,
        isLoading: false
      };
    }
    case TYPES.IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case TYPES.GET_ORDERS_SUMMARY_ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }

    default:
      return state;
  }
}
