import axiosInstance from "../../helpers/axiosInstance";
import { urlPrepare } from "../../helpers";
import { message } from "antd";
import Swal from "sweetalert2";

export const TYPES = {
  USER_NOT_LOGGEDIN: "USER_NOT_LOGGEDIN",

  GET_USER: "GET_USER",
  PAM_NEW_INVESTMENT: "PAM_NEW_INVESTMENT",
  PAM_NEW_INVESTMENT_IN_PROGRESS: "PAM_NEW_INVESTMENT_IN_PROGRESS",
  PAM_NEW_INVESTMENT_ERROR: "PAM_NEW_INVESTMENT_ERROR",

  GET_USERS: "GET_USERS",
  GET_USERS_IN_PROGRESS: "GET_USERS_IN_PROGRESS",
  GET_USERS_ROLE: "GET_USERS_ROLE",
  GET_USERS_BY_ROLE_IN_PROGRESS: "GET_USERS_BY_ROLE_IN_PROGRESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",

  GET_USERBYROLEERROR: "GET_USERBYROLEERROR",

  ADD_USER: "ADD_USER",
  ADD_USER_ERROR: "ADD_USER_ERROR",

  GET_USERBYID: "GET_USERBYID",
  GET_USERBYIDERROR: "GET_USERBYIDERROR",

  ACTIVATE_USER: "ACTIVATE_USER",
  ACTIVATE_USER_ERROR: "ACTIVATE_USER_ERROR",

  DESACTIVATE_USER: "DESACTIVATE_USER",
  DESACTIVATE_USER_ERROR: "DESACTIVATE_USER_ERROR",

  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  RESET_USER_PASSWORD_ERROR: "RESET_USER_PASSWORD_ERROR",

  GET_CRMBYCLIENTID: "GET_CRMBYCLIENTID",
  GET_CRMBYCLIENTIDERROR: "GET_CRMBYCLIENTIDERROR",

  ASSIGN_CLIENTS_TO_MANAGER: "ASSIGN_CLIENTS_TO_MANAGER",
  ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS:
    "ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS",
  ASSIGN_CLIENTS_TO_MANAGER_ERROR: "ASSIGN_CLIENTS_TO_MANAGER_ERROR",

  ASSIGN_USERERROR: "ASSIGN_USERERROR"
};

export function getUser() {
  return function (dispatch) {
    axiosInstance
      .get(process.env.REACT_APP_USER_ME)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.USER_NOT_LOGGEDIN
        });
      });
  };
}

export function getUserById(id) {
  return function (dispatch) {
    axiosInstance
      .get(urlPrepare(process.env.REACT_APP_USER_INFO, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERBYID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USERBYIDERROR
        });
      });
  };
}

export function getUsersByRole(role) {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_USERS_BY_ROLE_IN_PROGRESS });
    return axiosInstance
      .get(urlPrepare(process.env.REACT_APP_USERS_ROLE, { role }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERS_ROLE,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USERBYROLEERROR
        });
      });
  };
}

export function getUsers() {
  return function (dispatch) {
    dispatch({ type: TYPES.GET_USERS_IN_PROGRESS });
    axiosInstance
      .get(process.env.REACT_APP_USERS)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USERS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_USERS_ERROR
        });
      });
  };
}

export function addUser(newUser) {
  return function (dispatch) {
    return axiosInstance
      .post(process.env.REACT_APP_USERS, newUser)
      .then((res) => {
        dispatch({
          type: TYPES.ADD_USER,
          payload: res.data
        });
        Swal.fire({
          icon: 'success',
          title: 'User has been saved',
          showConfirmButton: false,
          timer: 2500
        });
      })
      .catch((err) => {
        const errorMsg = err.response ? err.response.data.message : "Cannot add user!"
        Swal.fire({
          icon: 'error',
          title: errorMsg,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        })
      });
  };
}

export function updateUser(id, formData) {
  return function (dispatch) {
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_USER_INFO, { id }), formData)
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER,
          payload: res.data
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: TYPES.RESET_USER_PASSWORD_ERROR
        });
        throw err;
      });
  };
}

export function resetPassword(id, oldPassword, newPassword) {
  return function (dispatch) {
    return axiosInstance
      .put(urlPrepare(process.env.REACT_APP_USER_RESET_PASSWORD, { id }), {
        oldPassword,
        newPassword
      })
      .then((res) => {
        message.success("Password reseted successfully!");
        dispatch({
          type: TYPES.RESET_USER_PASSWORD,
          payload: res.data
        });
      })
      .catch((err) => {
        message.error("password reset failed");
        dispatch({
          type: TYPES.RESET_USER_PASSWORD_ERROR
        });
      });
  };
}

export function activateUser(id) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_ACTIVATE_USER, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.ACTIVATE_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ACTIVATE_USER_ERROR
        });
      });
  };
}

export function desactivateUser(id) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_DESACTIVATE_USER, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.DESACTIVATE_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.DESACTIVATE_USER
        });
      });
  };
}

export function deleteUser(id) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_DELETE_USER, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.USER_NOT_LOGGEDIN
        });
      });
  };
}

export function recoverUser(id) {
  return function (dispatch) {
    return axiosInstance
      .post(urlPrepare(process.env.REACT_APP_RECOVER_USER, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_USER,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.USER_NOT_LOGGEDIN
        });
      });
  };
}

export function assignCR(id, assignedCRM) {
  return async function (dispatch) {
    let response = assignedCRM;

    return axiosInstance
      .post(
        urlPrepare(process.env.REACT_APP_USER_ASSIGN_CRM, { id }),
        response,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .catch((err) => {
        dispatch({
          type: TYPES.ASSIGN_USERERROR,
          payload: err.message
        });
      });
  };
}

export function removeCR(id, crmToRemove) {
  return async function (dispatch) {
    let response = crmToRemove;

    return axiosInstance
      .post(
        urlPrepare(process.env.REACT_APP_USER_REMOVE_CRM, { id }),
        response,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .catch((err) => {
        dispatch({
          type: TYPES.ASSIGN_USERERROR,
          payload: err.message
        });
      });
  };
}

export function getCRMByClientId(id) {
  return function (dispatch) {
    axiosInstance
      .get(urlPrepare(process.env.REACT_APP_CRM_INFO, { id }))
      .then((res) => {
        dispatch({
          type: TYPES.GET_CRMBYCLIENTID,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.GET_CRMBYCLIENTIDERROR,
          payload: err.message
        });
      });
  };
}

export function assignClientsToManager(values) {
  return function (dispatch) {
    dispatch({ type: TYPES.ASSIGN_CLIENTS_TO_MANAGER_IN_PROGRESS });
    return axiosInstance
      .post(`${process.env.REACT_APP_ASSIGN_CLIENTS_TO_MANAGER}`, values)
      .then((res) => {
        dispatch({
          type: TYPES.ASSIGN_CLIENTS_TO_MANAGER,
          payload: res.data
        });
        Swal.fire({
          title: "Clients",
          text: "Clients Assigned Successfully!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 2000
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ASSIGN_CLIENTS_TO_MANAGER_ERROR
        });
        Swal.fire({
          title: "Clients",
          text: err.message,
          type: "error"
        });
      });
  };
}

export function pamNewInvestment(investment) {
  return function (dispatch) {
    dispatch({ type: TYPES.PAM_NEW_INVESTMENT_IN_PROGRESS });
    return axiosInstance
      .post("/pam/create-investment", investment)
      .then((res) => {
        dispatch({
          type: TYPES.PAM_NEW_INVESTMENT,
          payload: res.data
        });
        Swal.fire({
          title: "Investment",
          text: "Investment Created Successfully!",
          icon: "success",
          confirmButtonText: "Close",
          timer: 5000
        }).then(() => {
          window.location.replace("/smartfolios/investments");
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.PAM_NEW_INVESTMENT_ERROR,
          payload: err.message
        });
        Swal.fire({
          title: "Investment",
          text: "failed to Create investment !",
          icon: "error",
          confirmButtonText: "Close",
          timer: 5000
        });
      });
  };
}
