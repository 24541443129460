import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const reportSummariesApi = SplitApiV2.injectEndpoints({
    endpoints: (builder) => ({
        getReportDashboardTotals: builder.query<any, any>({
            query: () => `/reports/dashboard-summary`,
            providesTags: ["reports"]
        }),
        getReports: builder.query<any, any>({
            query: ({ pageSize, pageNumber }) => urlPrepare(`/reports/?page_number=${pageSize}&page_size=${pageNumber}`),
            providesTags: ["reports"]
        }),
        getReportById: builder.query<any, any>({
            query: (id) => urlPrepare(`/reports/${id}`),
            providesTags: ["reports"]
        }),
        createReport: builder.mutation<any, any>({
            query: (body) => ({
                url: urlPrepare(`/reports`),
                method: "POST",
                body
            }),
            invalidatesTags: ["reports"]
        }),
        getReportAssets: builder.query<any, any>({
            query: (id) => urlPrepare(`/reports/assets`),
            providesTags: ["reports"]
        }),
    })
})

export const {
    useGetReportDashboardTotalsQuery,
    useGetReportsQuery,
    useGetReportByIdQuery,
    useCreateReportMutation,
    useGetReportAssetsQuery
} = reportSummariesApi;