import { TYPES } from "./actions";

let initState = {
  tickers: [],
  tickersSentiment: [],
  tickersToCompare: [],
  ticker: null,
  response: {},
  sentimentIsLoading: false,
  sentiments: {},
  isLoading: false,
  getTickerBySymbolInProgress: false
};

export function tickerASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_TICKER_IN_PROGRESS:
      return {
        ...state,
        tickersSentiment: action.payload
      };
    case TYPES.GET_TICKER_BY_SYMBOL_IN_PROGRESS:
      return {
        ...state,
        getTickerBySymbolInProgress: true
      };
    case TYPES.GET_TICKERS_TO_COMPARE:
      return {
        ...state,
        tickersToCompare: action.payload,
        isLoading: false
      };
    case TYPES.GET_TICKERS_SENTIMENTS:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.GET_TICKERS:
      return {
        ...state,
        tickers: action.payload.filter((item) => {
          return (
            !item?.name?.includes("21Shares") &&
            !item?.name?.includes("ProShares") &&
            !item?.name?.includes("Proshares")
          );
        })
      };
    case TYPES.GET_TICKER_BY_SYMBOL:
      return {
        ...state,
        ticker: action.payload,
        getTickerBySymbolInProgress: false
      };
    case TYPES.ADD_NEW_TICKER:
      return {
        ...state,
        response: action.payload
      };
    case TYPES.DELETE_TICKER: {
      return {
        ...state,
        tickers: state.tickers.filter(
          (ticker) => !action.payload.includes(ticker.symbol)
        )
      };
    }
    default:
      return state;
  }
}
