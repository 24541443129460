import { TYPES } from './actions';

let initState = {
  image: '',
  errors: {},
};

export function imageReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.IMAGES: {
      return {
        ...state,
        image: action.payload.message,
      };
    }

    case TYPES.IMAGES_ERROR: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default:
      return state;
  }
}
