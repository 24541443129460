import {
  GET_ALL_TEMPLATES,
  GET_TEMPLATE,
  ADD_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
  SUCCESS_NOTIF,
  DUP_KEY_ERROR,
  GENERAL_ERROR,
  RESET_NOTFICATION,
} from "./types";

const initilaState = {
  all: [],
  template: {},
  notification: null,
};

const editTemplate = (state, editedTemplate) => {
  const templates = state.all;
  const tempIndex = templates.findIndex(
    (template) => template._id === editedTemplate._id
  );

  const newState = {
    ...state,
    all: [
      ...templates.slice(0, tempIndex),
      editedTemplate,
      ...templates.slice(tempIndex + 1),
    ],
  };

  return newState;
};

export const templatesReducer = (state = initilaState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATES:
      return {
        ...state,
        all: action.payload,
      };
    case GET_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case ADD_TEMPLATE:
      return {
        ...state,
        all: [...state.all, action.payload],
      };
    case EDIT_TEMPLATE:
      return editTemplate(state, action.payload);
    case DELETE_TEMPLATE:
      return {
        ...state,
        all: state.all.filter(
          (template) => template.name !== action.payload.name
        ),
      };
    case SUCCESS_NOTIF:
    case DUP_KEY_ERROR:
    case GENERAL_ERROR:
      return {
        ...state,
        notification: action.payload,
      };
    case RESET_NOTFICATION:
      return {
        ...state,
        notification: null,
      };
    default:
      return state;
  }
};
