import { TYPES } from './actions'

let initState = {
  brokerFees: [],
  history: [],
}

export function brokerFeesReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_FEES: {
      return {
        ...state,
        brokerFees: action.payload,
      }
    }

    case TYPES.ADD_FEE: {
      state.brokerFees.push(action.payload)

      return state
    }

    case TYPES.UPDATE_FEE: {
      const brokerFees = state.brokerFees.map(fee => {
        if (fee.tickerId === action.payload.tickerId) {
          fee.current.percentage = action.payload.percentage
          fee.current.minCost.amount = action.payload.minCost * 100
        }

        return fee
      })
      return {
        ...state,
        brokerFees,
      }
    }

    case TYPES.GET_HISTORY: {
      return {
        ...state,
        history: action.payload,
      }
    }

    default:
      return state
  }
}
