import { TYPES } from "./actions";
const initState = {
  bankAccounts: [],
};

export function bankAccountsReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.GET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.payload,
      };
    }

    case TYPES.CREATE_BANK_ACCOUNT: {
      return {
        ...state,

        bankAccounts: [...state.bankAccounts, action.payload],
      };
    }

    default:
      return state;
  }
}
