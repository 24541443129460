import { createSlice } from "@reduxjs/toolkit";

export interface FinaTableFilter {
  positions: string;
  transactions: string;
  private_equity: string;
}

const initialState: FinaTableFilter = {
  positions: "OPEN",
  transactions: "ALL",
  private_equity: "ALL",
};

const tableFilterSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setPositionsFilter: (state: FinaTableFilter, action) => {
      state.positions = action.payload.filter;
    },
    setTransactionsFilter: (state: FinaTableFilter, action) => {
      state.transactions = action.payload.filter;
    },
    setPrivateEquityFilter: (state: FinaTableFilter, action) => {
      state.private_equity = action.payload.filter;
    },
  },
});

export const {
  setPositionsFilter,
  setTransactionsFilter,
  setPrivateEquityFilter,
} = tableFilterSlice.actions;

export default tableFilterSlice.reducer;
