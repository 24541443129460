import {
  GET_SENT_MAILS,
  SEND_MAIL,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_ERROR,
  GET_MAILS_ERROR,
  RESET_NOTIFICATION,
} from "./types";

const initialState = {
  all: [],
  notification: null,
};

export const mailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SENT_MAILS:
      return {
        ...state,
        all: action.payload,
      };

    case SEND_MAIL:
      return {
        ...state,
        all: [...state.all, action.payload],
      };

    case SEND_MAIL_SUCCESS:
    case SEND_MAIL_ERROR:
    case GET_MAILS_ERROR:
      return {
        ...state,
        notification: action.payload,
      };

    case RESET_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };

    default:
      return state;
  }
};
