import { TYPES } from "./actions";

let initState = {
  strategies: [],
  strateyGroups: [],
  strateyGroupById: {},
  strateyGroupByManagerId: [],
  strategie: {},
  response: {},
  addStrategyRes: {},
  createNewStrategyIsLoading: false,
  editStrategyIsLoading: false,
  getStrategyByIdIsLoading: false,
  editStrategyRes: {},
  deleteStrategyRes: {},
  isLoading: false,
  strategyPerformance: {},
  strategyPerformanceIsLoading: false
};

export function strategieASReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.ADD_NEW_STRATEGY_IN_PROGRESS: {
      return {
        ...state,
        createNewStrategyIsLoading: true
      };
    }

    case TYPES.GET_STRATEGY_PERFORMANCE_IN_PROGRESS: {
      return {
        ...state,
        strategyPerformanceIsLoading: true
      };
    }
    case TYPES.GET_STRATEGY_PERFORMANCE: {
      return {
        ...state,
        strategyPerformance: action.payload,
        strategyPerformanceIsLoading: false
      };
    }
    case TYPES.GET_STRATEGY_BY_ID_IN_PROGRESS: {
      return {
        ...state,
        getStrategyByIdIsLoading: true
      };
    }
    case TYPES.EDIT_STRATEGY_IN_PROGRESS: {
      return {
        ...state,
        editStrategyIsLoading: true
      };
    }
    case TYPES.GET_STRATEGIES_IN_PROGRESS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPES.GET_ALL_STRATEGY_GROUPS:
      return {
        ...state,
        strateyGroups: action.payload
      };
    case TYPES.GET_ALL_STRATEGY_GROUPS_BY_ID:
      return {
        ...state,
        strateyGroupById: action.payload
      };
    case TYPES.GET_ALL_STRATEGY_GROUPS_BY_MANAGER_ID:
      return {
        ...state,
        strateyGroupByManagerId: action.payload
      };
    //
    case TYPES.GET_ALL_STRATEGIES:
      return {
        ...state,
        strategies: action.payload,
        isLoading: false
      };
    case TYPES.GET_STRATEGY_BY_ID:
      return {
        ...state,
        strategie: action.payload,
        isLoading: false,
        getStrategyByIdIsLoading: false
      };
    case TYPES.ADD_NEW_STRATEGY:
      return {
        ...state,
        addStrategyRes: action.payload,
        createNewStrategyIsLoading: false
      };
    case TYPES.EDIT_STRATEGY:
      return {
        ...state,
        editStrategyRes: action.payload,
        editStrategyIsLoading: false
      };
    case TYPES.DELET_STRATEGIE:
      return {
        ...state,
        deleteStrategyRes: action.payload
      };
    case TYPES.DELETE_STRATEGIES: {
      const newStrategies = state.strategies.filter((strategy) => {
        return !action?.payload?.includes(strategy?.name);
      });
      return {
        ...state,
        strategies: newStrategies
      };
    }
    default:
      return state;
  }
}
