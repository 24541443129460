import { createSlice } from "@reduxjs/toolkit";
import { TYPES } from "./actions";

const initialState = {
  userRoles: [],
  userPermissions: [],
  roleDetails: {},
  deleteRoleRes: {},
  errors: null,

  isRolesLoading: false,
  isDetailsLoading: false,
};

const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TYPES.GET_USER_ROLES, (state, action) => {
        state.userRoles = action.payload;
        state.isRolesLoading = false;
      })
      .addCase(TYPES.GET_USER_PERMISSIONS, (state, action) => {
        state.userPermissions = action.payload;
      })
      .addCase(TYPES.GET_USER_ROLES_IN_PROGRESS, (state) => {
        state.isRolesLoading = true;
      })
      .addCase(TYPES.GET_ROLE_DETAILS_IN_PROGRESS, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(TYPES.GET_ROLE_DETAILS, (state, action) => {
        state.roleDetails = action.payload;
        state.isDetailsLoading = false;
      })
      .addCase(TYPES.DELETE_ROLE, (state, action) => {
        state.deleteRoleRes = action.payload;
      })
      .addCase(TYPES.DELETE_ROLE_ERROR, (state, action) => {
        state.errors = action.payload;
      });
  },
});

export default userRolesSlice.reducer;
