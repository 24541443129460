import { TYPES } from "./actions";
const initState = {
  data: [],
  count: 0,
  failedRoboServerOrders: [],
  isLoading: false,
  errors: undefined
};

export function roboserverOrdersReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.ROBOSERVER_GET_ORDERS: {
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count
      };
    }
    case TYPES.ROBOSERVER_GET_FAILED_ORDERS: {
      return {
        ...state,
        failedRoboServerOrders: action.payload
      };
    }
    case TYPES.ROBOSERVER_ORDERS_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case TYPES.ROBOSERVER_ORDERS_ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }
    default:
      return state;
  }
}
