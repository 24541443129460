import { investmentStatus, ordersStatus, rebalancingStatus } from "./constants";

const activeStatus = {
  withdraws: ordersStatus.executed,
  deposits: ordersStatus.executed,
  investments: investmentStatus.active,
  rebalancings: rebalancingStatus.resolved,
};

const pendingStatus = {
  withdraws: ordersStatus.pending,
  deposits: ordersStatus.pending,
  investments: investmentStatus.pending,
  rebalancings: rebalancingStatus.pending,
};

const openingStatus = {
  investments: investmentStatus.executed,
  rebalancings: rebalancingStatus.executed,
};

export const handleRebalancingSummary = (rebalancingData, rebalancingKey) => {
  const { ordersNavs, ordersList } = rebalancingData;
  const pendingRebalancing = ordersNavs[pendingStatus[rebalancingKey]];
  const openningRebalancingOrders = ordersNavs[openingStatus[rebalancingKey]];
  const resolvedRebalancingOrders = ordersNavs[activeStatus[rebalancingKey]];

  const pendingRebalancingOrders = {
    buys: pendingRebalancing?.buys + openningRebalancingOrders?.buys,
    sells: pendingRebalancing?.sells + openningRebalancingOrders?.sells,
  };

  const executedAmount =
    resolvedRebalancingOrders?.buys + resolvedRebalancingOrders?.sells;

  const pendingAmount =
    pendingRebalancingOrders?.buys + pendingRebalancingOrders?.sells;

  const updatedOrderList = ordersList.map((element) => {
    return {
      ...element,
      name: rebalancingKey,
      link: "investments",
    };
  });

  return {
    name: rebalancingKey,
    pendingAmount,
    executedAmount,
    ordersList: updatedOrderList,
    resolvedRebalancingOrders,
    pendingRebalancingOrders,
  };
};

export const handleOrderSummary = (orderData, orderType) => {
  const { ordersNavs, ordersList } = orderData;
  const OrderOpeningAmount = ordersNavs[openingStatus[orderType]] || 0;

  const pendingAmount =
    ordersNavs[pendingStatus[orderType]] + OrderOpeningAmount;

  const executedAmount = ordersNavs[activeStatus[orderType]] ?? ordersNavs;

  const displayedStatus = [
    pendingStatus[orderType],
    openingStatus[orderType],
    activeStatus[orderType],
  ];

  const filteredOrdersList = ordersList.filter(
    (element) => displayedStatus.includes(element?.status) || !element?.status
  );

  filteredOrdersList.forEach((element) => {
    element.name = orderType;
    element.link = orderType;
  });

  return {
    name: orderType,
    pendingAmount,
    executedAmount,
    ordersList: filteredOrdersList,
  };
};

export const handleExitSummary = (exitsData) => {
  const { ordersNavs, ordersList } = exitsData;

  const exitList = ordersList.filter((element) =>
      element?.status === "PENDING_EXIT" ||
      element?.status === "EXITED" ||
      element?.status === "ACTIVE_EXIT"
  );

  exitList.forEach((element) => {
    element.name = "exits";
    element.link = "investments";
  });

  return {
    pendingAmount: ordersNavs?.PENDING_EXIT + ordersNavs?.ACTIVE_EXIT,
    executedAmount: ordersNavs?.EXITED,
    ordersList: exitList,
  };
};
