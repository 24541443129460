import axiosInstance from '../../helpers/axiosInstance';

export const TYPES = {
	IMAGES: 'IMAGES',
	IMAGES_ERROR: 'IMAGES_ERROR'
};

export function getImage(filename) {
	return function(dispatch) {
		axiosInstance
			.get(`${process.env.REACT_APP_IMAGES}/${filename}`)
			.then((res) => {
				dispatch({
					type: TYPES.IMAGES,
					payload: res.data
				});
			})
			.catch((err) => {
				dispatch({
					type: TYPES.IMAGES_ERROR,
					payload: err.message
				});
			});
	};
}